import React from "react";
import { Router, Switch } from "react-router-dom";

import Loading from "./components/Loading";
import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { orange } from "@material-ui/core/colors";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import AdminLayout from "./layouts/Admin.js";
// import AuthLayout from "./layouts/Login.js";
// styles
import ProtectedRoute from "./views/Auth/ProtectedRoute";

const App = () => {
  const { isLoading, error } = useAuth0();
  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  const theme = createMuiTheme({
    palette: {
      type: "light",
      primary: orange,
    },
    typography: {
      button: {
        textTransform: "none",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Router history={history}>
          <div id="app" className="d-flex flex-column h-100">
            <Switch>
              <ProtectedRoute path="/" component={AdminLayout} />
            </Switch>
          </div>
        </Router>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default App;
