import React from "react";
// core components
import GridItem from "components/Grid/GridItem.js";
import { useParams } from "react-router-dom";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import Button from "@material-ui/core/Button";

import Loading from "components/Loading";
import CarouselService from "services/CarouselService";
import useAPI from "utils/useAPI";

var QRCode = require("qrcode.react");

const useStyles = makeStyles({
  // ...styles,

  inputLabel: {
    color: "#008BE8",
    font: "normal normal bold 16px/19px Lato",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  sidetext: {
    color: "#324152",
    float: "left",
    paddingLeft: "10px",
  },
  root: {
    width: "100%",
    // maxWidth: 460,
    position: "relative",
    overflow: "auto",
    // maxHeight: 600,
    paddingBottom: "5%",
    marginBottom: "5%",
  },
  media: {
    height: 140,
  },
});

function CarouselQR() {
  const classes = useStyles();
  const api = useAPI();
  const [carousel, setCarousel] = React.useState(null);
  let params = useParams();

  const fetchCarousel = async () => {
    CarouselService.getCarouselByToken(params.token).then((res) => {
      console.log(res);
      setCarousel(res);
    });
  };

  React.useEffect(() => {
    CarouselService.init(api);
    fetchCarousel();
  }, []);

  const handleScan = async () => {
    // console.log(carousel.qrCodeUrl)
    window.open(carousel.qrCodeUrl);
  };

  if (!carousel) {
    return <Loading />;
  }

  return (
    <GridContainer justify="center">
      <GridItem xs={12} sm={12} md={8} align="center">
        <Paper elevation={5} className={classes.root}>
          {/* <Card className={classes.root} variant="outlined"> */}

          <CardContent>
            {/* <Typography gutterBottom variant="h5" component="h2">
              carousel Details
            </Typography> */}

            <CardHeader title="QR Code" subheader={carousel.nick_name} />

            {/* <img src={car} alt="Home header" width="70%" /> */}
            <List>
              {carousel && <QRCode size={200} value={carousel.qrCodeUrl} />}
              {/* <ListItem>
                <ListItemText primary={ticket.name} secondary="Name" />
              </ListItem> */}
            </List>
          </CardContent>

          <CardActions>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={handleScan}
            >
              Park
            </Button>
          </CardActions>
        </Paper>
      </GridItem>
    </GridContainer>
  );
}

export default CarouselQR;
