import Axios from "axios";

const requests = [];
const { CancelToken } = Axios;
let api = Axios;

const getVehicles = async (pageSize, page, search, orderBy, orderDirection) => {
  try {
    let cancel;
    const response = await api.get(
      "/admin/vehicles",
      { params: { pageSize, page, search, orderBy, orderDirection } },
      {
        cancelToken: new CancelToken((c) => {
          cancel = c;
          requests.push(cancel);
        }),
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getVehicle = async (id) => {
  try {
    let cancel;
    const response = await api.get(`/admin/vehicle/${id}`, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const deleteVehicle = async (id) => {
  try {
    let cancel;
    const response = await api.delete(
      "/admin/vehicles",
      { data: { id: id } },
      {
        cancelToken: new CancelToken((c) => {
          cancel = c;
          requests.push(cancel);
        }),
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const storeVehicle = async (data) => {
  try {
    let cancel;
    const response = await api.post("/admin/vehicles", data, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const updateVehicle = async (data) => {
  try {
    let cancel;
    const response = await api.put("/admin/vehicles", data, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getVehicleMakes = async (search) => {
  try {
    let cancel;
    const response = await api.get("/admin/vehicle-makes/" + search, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getVehicleModel = async (id) => {
  try {
    let cancel;
    const response = await api.get("/admin/vehicle-models/" + id, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
const init = async (axiosApi) => {
  api = axiosApi;
};

const VehicleService = {
  init,
  getVehicle,
  getVehicles,
  deleteVehicle,
  storeVehicle,
  updateVehicle,
  getVehicleMakes,
  getVehicleModel,
};

export default VehicleService;
