import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import VehicleService from "services/VehicleService";
import useAPI from "utils/useAPI";
import buttons from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";
import { useHistory } from "react-router-dom";
import tableStyles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import MaterialTable, { MTableToolbar } from "material-table";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    buttons,
  },
  ...tableStyles,
};

const useStyles = makeStyles(styles);

export default function ReactTables() {
  const api = useAPI();
  const history = useHistory();
  let path = "/vehicles/edit/";
  const tableRef = React.createRef();

  React.useEffect(() => {
    VehicleService.init(api);
  }, [api]);

  const classes = useStyles();
  return (
    <GridContainer>
      <div className={classes.cardContentLeft}></div>
      <GridItem xs={12}>
        <Card>
          <MaterialTable
            title="Vehicles"
            tableRef={tableRef}
            columns={[
              { title: "Make", field: "make" },
              { title: "Model", field: "model" },
              { title: "Plate", field: "plate" },
              { title: "Color", field: "color" },
              { title: "Zip", field: "zip" },
              { title: "User", field: "user" },
            ]}
            data={(query) =>
              new Promise((resolve, reject) => {
                VehicleService.getVehicles(
                  query.pageSize,
                  query.page + 1,
                  query.search,
                  query.orderBy ? query.orderBy.field : null,
                  query.orderDirection
                )
                  .then((res) => {
                    resolve({
                      data: res.results.map((vehicle) => {
                        return {
                          id: vehicle.id,
                          make: vehicle.make,
                          model: vehicle.model,
                          plate: vehicle.plate,
                          color: vehicle.color,
                          zip: vehicle.zip,
                          user: (vehicle.users && vehicle.users.name) || "NA",
                        };
                      }),
                      page: res.page - 1,
                      totalCount: res.total,
                    });
                  })
                  .catch(reject);
              })
            }
            actions={[
              {
                icon: "add",
                tooltip: "Add Vehicle",
                position: "toolbar",
                onClick: () => {
                  history.push("/vehicles/add");
                },
              },
              (rowData) => ({
                icon: "edit",
                iconProps: { fontSize: "small" },
                tooltip: "Update Vehicle",
                onClick: () => {
                  history.push(path + rowData.id);
                },
              }),
              (rowData) => ({
                icon: "delete",
                iconProps: { fontSize: "small", style: { color: "red" } },
                tooltip: "Delete Vehicle",
                onClick: async () => {
                  await VehicleService.deleteVehicle(rowData.id);
                  tableRef.current && tableRef.current.onQueryChange();
                },
              }),
            ]}
            components={{
              Toolbar: (props) => (
                <div style={{ alignItems: "center", paddingRight: "0%" }}>
                  <CardHeader color="primary" icon>
                    <CardIcon color="rose">
                      <LocalShippingIcon />
                    </CardIcon>
                  </CardHeader>
                  <MTableToolbar {...props} />
                </div>
              ),
            }}
            options={{
              actionsColumnIndex: -1,
              debounceInterval: 500,
              filtering: false,
              sorting: true,
              pageSize: 10,
            }}
          />
        </Card>
      </GridItem>
    </GridContainer>
  );
}
