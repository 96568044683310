import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import Site from "components/Site/Site.js";
import Button from "components/CustomButtons/Button.js";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  ...customSelectStyle,
};

class Step3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      simpleSelect: "",
      desgin: false,
      code: false,
      develop: false,
      siteList: [
        {
          siteName: "",
          parkingCarousel: true,
          monthlyMembership: false,
          payGo: false,
          ticketSystem: false,
        },
      ],
    };
  }
  sendState() {
    return this.state;
  }
  handleSimple = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  isValidated() {
    return true;
  }
  handleAdd() {
    console.log("Here?", this.state.siteList);
    this.setState({
      siteList: [
        ...this.state.siteList,
        {
          siteName: "",
          parkingCarousel: true,
          monthlyMembership: false,
          payGo: false,
          ticketSystem: false,
        },
      ],
    });
  }
  updateSiteList = (id, data) => {
    console.log("11111111", id, data);
    this.setState({
      siteList: [
        ...this.state.siteList.slice(0, id),
        {
          ...this.state.siteList[id],
          data,
        },
        ...this.state.siteList.slice(id + 1),
      ],
    });
    // do something with value in parent component, like save to state
  };
  render() {
    const { classes } = this.props;
    return (
      <GridContainer justify="center">
        <Grid
          container
          direction="row"
          alignItems="center"
          item
          xs={12}
          sm={12}
          md={12}
          align="center"
          spacing={0}
        >
          {/* <Grid item xs={9} sm={9} mad={9}>
            <Site id={0} data={this.state.siteList[0]} />
          </Grid> */}
          <Grid item xs={12} sm={12} md={12}>
            <Button
              color="primary"
              className={classes.registerButton}
              onClick={() => this.handleAdd()}
              // fullWidth
              style={{ textAlign: "center", minWidth: 225 }}
            >
              Add
            </Button>
            {/* <Fab
              color="primary"
              aria-label="add"
              onClick={() => this.handleAdd()}
            >
              <AddIcon />
            </Fab> */}
          </Grid>
        </Grid>

        {this.state.siteList.map((prop, key) => {
          return (
            <Site
              key={key}
              id={key}
              data={prop}
              updateSiteList={this.updateSiteList}
            />
          );
        })}
      </GridContainer>
    );
  }
}

Step3.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(style)(Step3);
