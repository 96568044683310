import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// import FormLabel from "@material-ui/core/FormLabel";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import InputAdornment from "@material-ui/core/InputAdornment";
// import Radio from "@material-ui/core/Radio";
// import Checkbox from "@material-ui/core/Checkbox";

// @material-ui/icons
// import Check from "@material-ui/icons/Check";
// import Clear from "@material-ui/icons/Clear";
// import Contacts from "@material-ui/icons/Contacts";
// import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";
import UserService from "services/UserService";
import DiscountService from "services/DiscountService";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
// import CardText from "components/Card/CardText.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
// import { BrowserRouter as Router, Link, useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import useAPI from "utils/useAPI";
import ParkingSessionService from "services/ParkingSessionService";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import LocationService from "services/LocationService";
import PropTypes from "prop-types";
import moment from "moment";

const useStyles = makeStyles(styles);
const types = [{ name: "Amount" }, { name: "Percentage" }, { name: "Hours" }];
function DiscountForm({ id = null }) {
  const [isEdit] = React.useState(id ? true : false);
  const classes = useStyles();
  const history = useHistory();
  const api = useAPI();
  // const [users, setUsers] = React.useState([]);
  const [locationList, setLocationList] = React.useState([]);
  const [values, setValues] = React.useState({
    code: "",
    value: "",
    type: "",
    user: [],
    location: [],
    applies_to: [],
    to_all_user: true,
    to_all_locations: true,
    start_time: "",
    expire_time: "",
    new_signup: false,
    apply_once_per_customer: false,
  });
  const [errors, setErrors] = React.useState({
    code: "",
    value: "",
    type: "",
    user: "",
    start_time: "",
    expire_time: "",
    applies_to: "",
    location: "",
  });

  // function transformDiscount(res) {
  //   let transformed_fees = Fee;
  //   transformed_fees = transformed_fees.map((fee) => {
  //     return {
  //       id: fee.id,
  //       amount: fee.amount,
  //       duration: fee.duration + " " + fee.type,
  //     };
  //   });
  //   return transformed_fees;
  // }

  const getDate = (dateTime) => {
    return moment(dateTime).format("MM/DD/YYYY hh:mm A");
  };

  const fetchDiscount = async () => {
    await DiscountService.getDiscount(id).then((res) => {
      res.applies_to = res.apply_to_locations === 1 ? ["location"] : [];
      // res.applies_to = [];
      let locations = [];
      res.location.forEach((item) => {
        locations.push(item.id);
      });
      res.location = locations;
      setValues(res);
    });
  };

  React.useEffect(() => {
    ParkingSessionService.init(api);
    UserService.init(api);
    LocationService.init(api);
    DiscountService.init(api);
    if (id) fetchDiscount();
    fetchLocationList();
    // fetchUser();
  }, [api]);

  // const fetchUser = async () => {
  //   await UserService.getUsers().then((res) => {
  //     setUsers(res.results);
  //   });
  // };

  const fetchLocationList = async () => {
    await LocationService.getLocations().then((res) => {
      setLocationList(
        res.map((item) => {
          return {
            id: item.id,
            name: item.name + " - " + item.zipcode,
            value: item.id,
          };
        })
      );
      if (res.length === 1) {
        const defaultLocation = [res[0].id];
        setValues({ ...values, location: defaultLocation });
      }
    });
  };

  const handleDate = (prop, val) => {
    setValues({ ...values, [prop]: moment(val).format("YYYY-MM-DD HH:mm:ss") });
  };

  const handleCheckbox = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.checked });
  };

  const handleChange = (prop, val) => {
    setValues({ ...values, [prop]: val });
    if (verifyLength(val, 1)) {
      setErrors({ ...errors, [prop]: "success" });
    } else {
      setErrors({ ...errors, [prop]: "error" });
    }
  };

  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  const handleCode = async () => {
    let val = Math.random().toString(36).slice(-8).toUpperCase();
    setValues({ ...values, code: val });
  };
  const handleSave = async () => {
    let error = 0;
    let newErrors = errors;
    [
      "code",
      "type",
      "start_time",
      "expire_time",
      "applies_to",
      "location",
    ].forEach((field) => {
      if (field === "location") {
        if (
          values.applies_to.find((element) => element === "location") &&
          !values.to_all_locations &&
          !values["location"].length
        ) {
          newErrors["location"] = "error";
          error = 1;
        }
      } else if (values[field] === "" || !values[field].length) {
        newErrors[field] = "error";
        error = 1;
      }
    });
    setErrors({ ...newErrors });
    if (error) return;

    if (id) await DiscountService.updateDiscount(values);
    else await DiscountService.addDiscount(values);

    let path = `/admin/discount`;
    history.push(path);
  };
  const cancelRoute = () => {
    let path = `/admin/discount`;
    history.push(path);
  };
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <LocalAtmIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Add Discount</h4>
          </CardHeader>
          <CardBody>
            <form>
              <GridItem xs={12} sm={12}>
                <GridContainer>
                  <GridItem xs={8} sm={8} md={8}>
                    <TextField
                      error={errors.code === "error"}
                      autoFocus
                      margin="dense"
                      id="promo_code"
                      label="Promo Code"
                      variant="outlined"
                      type="text"
                      fullWidth
                      value={values.code}
                      onChange={(event) => {
                        handleChange("code", event.target.value);
                      }}
                    />
                  </GridItem>
                  <GridItem xs={4} sm={4} md={4}>
                    <Button color="success" onClick={handleCode}>
                      Auto Generate Code
                    </Button>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <FormControl
                fullWidth
                margin="dense"
                className={classes.formControl}
                variant="outlined"
              >
                <InputLabel>Type *</InputLabel>
                <Select
                  id="type"
                  fullWidth
                  label="Type *"
                  value={values.type}
                  onChange={(e) => {
                    handleChange("type", e.target.value);
                  }}
                  error={errors.type === "error"}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Choose type *
                  </MenuItem>

                  {types.map((type) => (
                    <MenuItem
                      value={`${type.name}`}
                      key={`${type.name}`}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                    >
                      {`${type.name}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                error={errors.value === "error"}
                autoFocus
                margin="dense"
                id="value"
                label="Value"
                variant="outlined"
                type="text"
                fullWidth
                value={values.value}
                onChange={(event) => {
                  handleChange("value", event.target.value);
                }}
              />
              <FormControl
                fullWidth
                margin="dense"
                className={classes.selectFormControl}
                variant="outlined"
              >
                <InputLabel
                  htmlFor="multiple-select"
                  className={classes.selectLabel}
                >
                  Applies To
                </InputLabel>
                <Select
                  multiple
                  label="Applies To"
                  id="applies_to"
                  fullWidth
                  value={values.applies_to}
                  onChange={(e) => {
                    handleChange("applies_to", e.target.value);
                  }}
                  error={errors.type === "error"}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Choose Applies to
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="location"
                  >
                    Location
                  </MenuItem>
                </Select>
              </FormControl>
              {/* {values.applies_to.find((element) => element === "user") && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.to_all_user}
                      onChange={handleCheckbox("to_all_user")}
                    />
                  }
                  label="Applies to All Users"
                />
              )} */}
              {values.applies_to.find((element) => element === "location") && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.to_all_locations}
                      onChange={handleCheckbox("to_all_locations")}
                    />
                  }
                  label="Applies to All Locations"
                />
              )}
              {/* {!values.to_all_user && (
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="multiple-select"
                    className={classes.selectLabel}
                  >
                    Choose Specific User to Apply promoCode
                  </InputLabel>
                  <Select
                    multiple
                    value={values.user}
                    error={errors.user === "error"}
                    onChange={(e) => {
                      handleChange("user", e.target.value);
                    }}
                    MenuProps={{ className: classes.selectMenu }}
                    classes={{ select: classes.select }}
                    inputProps={{
                      name: "multipleSelect",
                      id: "multiple-select",
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      Choose User
                    </MenuItem>
                    {users.map((user, index) => (
                      <MenuItem
                        value={`${user.id}`}
                        key={`${user.id}`}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                      >
                        {`${user.name}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )} */}
              <br />
              <br />
              {!values.to_all_locations && (
                <FormControl
                  fullWidth
                  className={classes.selectFormControl}
                  variant="outlined"
                  margin="dense"
                >
                  <InputLabel>
                    Choose Specific Location to Apply PromoCode
                  </InputLabel>
                  <Select
                    multiple
                    id="location"
                    fullWidth
                    label="Choose Specific Location to Apply PromoCode"
                    value={values.location}
                    onChange={(e) => {
                      handleChange("location", e.target.value);
                    }}
                    error={errors.location === "error"}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      Choose Specific Location to Apply PromoCode
                    </MenuItem>
                    {locationList &&
                      locationList.map((location) => (
                        <MenuItem
                          value={`${location.id}`}
                          key={`${location.id}`}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                        >
                          {`${location.name}`}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}

              <FormControl fullWidth>
                <InputLabel
                  htmlFor="simple-select"
                  className={classes.selectLabel}
                >
                  Promocode Start Time
                </InputLabel>

                <Datetime
                  onChange={(e) => {
                    handleDate("start_time", e);
                  }}
                  inputProps={{
                    value: isEdit
                      ? getDate(`${values.start_time}`)
                      : `${values.start_time}`,
                    error: true,
                  }}
                />
              </FormControl>
              <FormControl fullWidth>
                <InputLabel
                  htmlFor="simple-select"
                  className={classes.selectLabel}
                >
                  Promocode End Time
                </InputLabel>

                <Datetime
                  onChange={(e) => {
                    handleDate("expire_time", e);
                  }}
                  inputProps={{
                    value: isEdit
                      ? getDate(`${values.expire_time}`)
                      : `${values.expire_time}`,
                  }}
                />
              </FormControl>
              <div className={classes.checkboxAndRadio}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.new_signup}
                      onChange={handleCheckbox("new_signup")}
                    />
                  }
                  label="Apply only to new Signup"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.apply_once_per_customer}
                      onChange={handleCheckbox("apply_once_per_customer")}
                    />
                  }
                  label="Apply once per customer"
                />
              </div>

              <Button color="success" onClick={handleSave}>
                Submit
              </Button>
              <Button color="danger" onClick={cancelRoute}>
                Cancel
              </Button>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

DiscountForm.propTypes = {
  id: PropTypes.string,
};

DiscountForm.defaultProps = {
  id: null,
};

export default DiscountForm;
