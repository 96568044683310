import React from "react";
import MUIDataTable from "mui-datatables";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardIcon from "components/Card/CardIcon.js";
import { Tooltip, IconButton, Typography } from "@material-ui/core";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import LocationService from "services/LocationService";
import useAPI from "utils/useAPI";
import buttons from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";
import { useHistory } from "react-router-dom";
import tableStyles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    buttons,
  },
  ...tableStyles,
};

const useStyles = makeStyles(styles);

export default function ReactTables() {
  const api = useAPI();
  const history = useHistory();
  let path = "/location/edit/";
  const [data, setData] = React.useState(null);
  const fetchLocationList = () => {
    LocationService.getLocationList()
      .then((res) => {
        const d = transform(res.results);
        setData(d);
      })
      .catch();
  };
  React.useEffect(() => {
    LocationService.init(api);
    fetchLocationList();
  }, [api]);

  function transform(locations) {
    let transformed_data = locations;
    transformed_data = transformed_data.map((ses) => {
      return {
        id: ses.id,
        name: ses.name,
        latitude: ses.latitude,
        longitude: ses.longitude,
        zipcode: ses.zipcode,
        available_space: ses.available_space ? ses.available_space : "NA",
        actions: (
          <>
            <div display="flex">
              <Tooltip title="Update Location" aria-label="">
                <IconButton
                  onClick={async () => {
                    history.push(path + ses.id);
                  }}
                >
                  <EditIcon color="primary" />
                </IconButton>
              </Tooltip>
            </div>
          </>
        ),
      };
    });
    return transformed_data;
  }

  const classes = useStyles();

  const columns = [
    {
      label: "Name",
      name: "name",
    },
    {
      label: "Latitude",
      name: "latitude",
    },
    {
      label: "Longitude",
      name: "longitude",
    },
    {
      label: "Zipcode",
      name: "zipcode",
    },
    {
      label: "Available Space",
      name: "available_space",
    },
    {
      label: "Actions",
      name: "actions",
    },
  ];
  const options = {
    filter: false, // Hide filters
    print: false, // Hide print
    download: false, // Hide download
    selectableRowsHeader: false, // Hide checkbox in header
    selectableRowsHideCheckboxes: true, // Hide checkbox for every row
    customToolbar: () => {
      return (
        <Tooltip title="Add Location" aria-label="">
          <IconButton
            onClick={() => {
              history.push("/location/add");
            }}
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      );
    },
  };
  return (
    <GridContainer>
      <div className={classes.cardContentLeft}></div>
      <GridItem xs={12}>
        <Card>
          {data && (
            <MUIDataTable
              title={
                <div>
                  <CardIcon color="rose">
                    <LocationOnIcon style={{ color: "white" }} />{" "}
                  </CardIcon>
                  <Typography variant="h6">Location</Typography>
                </div>
              }
              data={data}
              columns={columns}
              options={options}
            />
          )}
        </Card>
      </GridItem>
    </GridContainer>
  );
}
