/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import Radio from "@material-ui/core/Radio";
import { Autocomplete } from "@material-ui/lab";
import RadioGroup from "@material-ui/core/RadioGroup";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
// core components
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import { useHistory } from "react-router-dom";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import UserService from "services/UserService";
import VehicleService from "services/VehicleService";
import OrganizationService from "services/OrganizationService";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import useAPI from "utils/useAPI";
const useStyles = makeStyles(styles);

function FeeForm({ id = null }) {
  var numberRex = new RegExp("^[0-9]+$");
  const [users, setUsers] = React.useState([]);
  const [isEdit] = React.useState(id ? true : false);
  const [OrganizationLabel] = React.useState(
    id ? "Update Organization" : "Add Organization"
  );

  const [organization, setOrganization] = React.useState({
    name: "",
    mobile: "",
    head: "",
  });
  const api = useAPI();
  const history = useHistory();

  const [errors, setErrors] = React.useState({
    name: "",
    mobile: "",
    head: "",
  });

  const fetchFeeStructure = async () => {
    console.log("Entered");
    await FeeStructureService.getFees(id).then((res) => {
      setFee(res);
      console.log("res", res);
    });
  };
  const fetchUser = async () => {
    await UserService.getUsers().then((res) => {
      setUsers(res.results);
    });
  };

  React.useEffect(() => {
    OrganizationService.init(api);
    UserService.init(api);
    fetchUser();
    // if (id) fetchFeeStructure();
  }, [api]);

  const handleChange = (prop, val) => {
    console.log("Changed", prop, val);
    setFee({ ...fee, [prop]: val });
    if (verifyLength(val, 1)) {
      setErrors({ ...errors, [prop]: "success" });
    } else {
      setErrors({ ...errors, [prop]: "error" });
    }
  };

  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  const verifyNumber = (value) => {
    if (numberRex.test(value) && value.length >= 1) {
      return true;
    }
    return false;
  };

  const registerClick = async () => {
    let error = 0;
    let newErrors = errors;
    ["name", "mobile", "head"].forEach((field) => {
      if (organization[field] === "") {
        newErrors[field] = "error";
        error = 1;
      }
    });
    setErrors({ ...newErrors });

    if (error) return;

    if (id) {
    } else await OrganizationService.storeOrganization(organization);
    history.push("/admin/organization");
  };
  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={6}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <AccountBalanceIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{OrganizationLabel}</h4>
          </CardHeader>
          <CardBody>
            <form>
              <CustomInput
                // success={registerPlateState === "success"}
                error={errors.name === "error"}
                labelText="Name *"
                id="name"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  onChange: (event) => {
                    console.log("Changes?", event.target.value);
                    if (verifyLength(event.target.value, 1)) {
                      setErrors({ ...errors, name: "success" });
                    } else {
                      setErrors({ ...errors, name: "error" });
                    }
                    setOrganization({
                      ...organization,
                      name: event.target.value,
                    });
                  },
                  autoComplete: "off",
                  value: `${organization.name}`,
                }}
              />
              <CustomInput
                // success={registerPlateState === "success"}
                error={errors.mobile === "error"}
                labelText="Contact *"
                id="mobile"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  onChange: (event) => {
                    if (verifyNumber(event.target.value)) {
                      setErrors({ ...errors, mobile: "success" });
                    } else {
                      setErrors({ ...errors, mobile: "error" });
                    }
                    setOrganization({
                      ...organization,
                      mobile: event.target.value,
                    });
                  },
                  type: "number",
                  autoComplete: "off",
                  value: `${organization.mobile}`,
                }}
              />
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  htmlFor="simple-select"
                  className={classes.selectLabel}
                >
                  Choose Organization Head
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={organization.head}
                  onChange={(e) => {
                    setOrganization({
                      ...organization,
                      head: e.target.value,
                    });
                  }}
                  error={errors.head === "error"}
                  inputProps={{
                    name: "simpleSelect",
                    id: "simple-select",
                  }}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Choose User
                  </MenuItem>

                  {users.map((user, index) => (
                    <MenuItem
                      value={`${user.id}`}
                      key={`${user.id}`}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                    >
                      {`${user.name}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <div className={classes.formCategory}>
                <small>*</small> Required fields
              </div>

              <Button
                color="rose"
                onClick={registerClick}
                className={classes.registerButton}
              >
                {OrganizationLabel}
              </Button>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

FeeForm.propTypes = {
  id: PropTypes.string,
};

FeeForm.defaultProps = {
  id: null,
};

export default FeeForm;
