import Axios from "axios";

const requests = [];
const { CancelToken } = Axios;
let api = Axios;

const getNotifications = async () => {
  try {
    let cancel;
    const response = await api.get("/admin/notifications", {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const markAsRead = async (id) => {
  try {
    let cancel;
    const response = await api.get(`/admin/notifications/${id}/read`, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const deleteNotification = async (id) => {
  try {
    let cancel;
    const response = await api.delete(`/admin/notifications/${id}`, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const init = async (axiosApi) => {
  api = axiosApi;
};

const NotificationService = {
  init,
  getNotifications,
  markAsRead,
  deleteNotification,
};

export default NotificationService;
