import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import ParkingSessionService from "services/ParkingSessionService";
import useAPI from "utils/useAPI";
import buttons from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";
// import { useHistory } from "react-router-dom";
import tableStyles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import LocalParkingIcon from "@material-ui/icons/LocalParking";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import MaterialTable, { MTableToolbar } from "material-table";
import moment from "moment";
import Chip from "@material-ui/core/Chip";
import GetAppIcon from "@material-ui/icons/GetApp";
import history from "utils/history";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    buttons,
  },
  ...tableStyles,
};

const useStyles = makeStyles(styles);

export default function ParkingSessions() {
  const api = useAPI();
  // const history = useHistory();
  const classes = useStyles();
  const tableRef = React.createRef();
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(false);
  // let path = "/pins/edit/";

  // function notify(message) {
  //   setError(message);
  //   setOpen(true);
  // }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setError("");
  };

  React.useEffect(() => {
    ParkingSessionService.init(api);
    // fetchPins();
  }, [api]);

  const retrieveParkingSession = async (data) => {
    await ParkingSessionService.retrieveParkingSession(data);
  };

  function transformSession(session) {
    let transformed_session = session;
    transformed_session = transformed_session.map((ses) => {
      const isParked = String(ses.status).toUpperCase() !== "RETRIEVED";
      return {
        id: ses.id,
        user_vehicle_pin: ses.user_vehicle_pin,
        vehicle_name: ses.vehicle_name,
        vehicle_id: ses.vehicle_id,
        u_id: ses.user.id,
        user_id: ses.user ? ses.user.name : "NA",
        duration: ses.duration,
        isParked,
        end_time:
          (!isParked && moment(ses.end_time).format("MM/DD/YYYY hh:mm A")) ||
          "NA",
        start_time:
          (ses.start_time &&
            moment(ses.start_time).format("MM/DD/YYYY hh:mm A")) ||
          "NA",
        status: (
          <Chip
            label={String(ses.status).toUpperCase()}
            variant="outlined"
            color={ses.status === "retrieved" ? "primary" : "secondary"}
          />
        ),
      };
    });
    return transformed_session;
  }
  // React.useEffect(() => {
  //   setItemsGrid(
  //     items.map((item) => {
  //       const isParked = String(item.status).toUpperCase() !== "RETRIEVED";
  //       return {
  //         id: item.id,
  //         user_vehicle_pin: item.user_vehicle_pin,
  //         vehicle_name: item.vehicle_name,
  //         user: item.user ? item.user.name : "NA",
  //         isParked,
  //         status: (
  //           <Chip
  //             label={String(item.status).toUpperCase()}
  //             variant="outlined"
  //             color={item.status === "retrieved" ? "primary" : "secondary"}
  //           />
  //         ),
  //         parkedAt: moment(item.start_time).format("MM/DD/YYYY hh:mm A"),
  //         retrievedAt:
  //           (!isParked && moment(item.end_time).format("MM/DD/YYYY hh:mm A")) ||
  //           "NA",
  //         duration: !isParked ? item.duration : "NA",
  //       };
  //     })
  //   );
  // }, [items]);

  return (
    <GridContainer>
      <div className={classes.cardContentLeft}></div>
      <GridItem xs={12}>
        <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
            {error}
          </Alert>
        </Snackbar>
        <Card>
          <MaterialTable
            title="Parking Sessions"
            tableRef={tableRef}
            columns={[
              { title: "User Vehicle Pin", field: "user_vehicle_pin" },
              { title: "Vehicle", field: "vehicle_name" },
              { title: "User", field: "user_id" },
              { title: "Status", field: "status" },
              {
                title: "Parked At",
                field: "start_time",
                type: "datetime",
                cellStyle: { fontSize: 12 },
              },
              {
                title: "Retrieved At",
                field: "end_time",
                type: "datetime",
                cellStyle: { fontSize: 12 },
              },
              { title: "Duration (minutes)", field: "duration" },
            ]}
            data={(query) =>
              new Promise((resolve, reject) => {
                ParkingSessionService.list(
                  query.pageSize,
                  query.page + 1,
                  query.search,
                  query.orderBy ? query.orderBy.field : null,
                  query.orderDirection
                )
                  .then((res) => {
                    resolve({
                      data: transformSession(res.results),
                      page: res.page - 1,
                      totalCount: res.total,
                    });
                  })
                  .catch(reject);
              })
            }
            actions={[
              (rowData) =>
                (rowData.isParked && {
                  icon: () => (
                    <GetAppIcon style={{ color: "red" }} fontSize="small" />
                  ),
                  tooltip: "Retrieve",
                  onClick: () => {
                    retrieveParkingSession(rowData).then(() => {
                      console.log("Completed");
                      tableRef.current && tableRef.current.onQueryChange();
                    });
                  },
                }) ||
                null,
              (rowData) =>
                (rowData.isParked && {
                  icon: () => <LocalAtmIcon />,
                  tooltip: "Apply Discount",
                  onClick: () => {
                    history.push(`/parking-sessions/${rowData.id}/discount`);
                  },
                }) ||
                null,
            ]}
            components={{
              Toolbar: (props) => (
                <div style={{ alignItems: "center", paddingRight: "0%" }}>
                  <CardHeader color="primary" icon>
                    <CardIcon color="rose">
                      <LocalParkingIcon />
                    </CardIcon>
                  </CardHeader>
                  <MTableToolbar {...props} />
                </div>
              ),
            }}
            options={{
              actionsColumnIndex: -1,
              debounceInterval: 500,
              filtering: false,
              sorting: true,
              pageSize: 10,
            }}
          />
        </Card>
      </GridItem>
    </GridContainer>
  );
}
