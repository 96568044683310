import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import PinService from "services/PinService";
import useAPI from "utils/useAPI";
import buttons from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";
import { useHistory } from "react-router-dom";
import PersonAddDisabledIcon from "@material-ui/icons/PersonAddDisabled";
import tableStyles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import FiberPinIcon from "@material-ui/icons/FiberPin";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import MaterialTable, { MTableToolbar } from "material-table";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    buttons,
  },
  ...tableStyles,
};

const useStyles = makeStyles(styles);

export default function ReactTables() {
  const api = useAPI();
  const history = useHistory();
  const classes = useStyles();
  const tableRef = React.createRef();

  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(false);
  let path = "/pins/edit/";

  function notify(message) {
    setError(message);
    setOpen(true);
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setError("");
  };
  function transformPin(pins) {
    let transformed_pin = pins;
    transformed_pin = transformed_pin.map((pin) => {
      return {
        id: pin.id,
        user_vehicle_pin: pin.user_vehicle_pin,
        plc_pin: pin.plc_pin,
        available: pin.available ? "Yes" : "No",
        user: pin.user_id ? pin.users.name : "NA",
        carousel: pin.carousel_id ? pin.carousels.nick_name : "NA",
      };
    });
    return transformed_pin;
  }

  React.useEffect(() => {
    PinService.init(api);
    // fetchPins();
  }, [api]);

  return (
    <GridContainer>
      <div className={classes.cardContentLeft}></div>
      <GridItem xs={12}>
        <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
            {error}
          </Alert>
        </Snackbar>
        <Card>
          <MaterialTable
            title="Pins"
            tableRef={tableRef}
            columns={[
              { title: "User Vehicle Pin", field: "user_vehicle_pin" },
              { title: "PLC PIN", field: "plc_pin" },
              { title: "Available", field: "available" },
              { title: "User", field: "user", sorting: false },
              { title: "Carousel", field: "carousel", sorting: false },
            ]}
            data={(query) =>
              new Promise((resolve, reject) => {
                PinService.getPins(
                  query.pageSize,
                  query.page + 1,
                  query.search,
                  query.orderBy ? query.orderBy.field : null,
                  query.orderDirection
                )
                  .then((res) => {
                    resolve({
                      data: transformPin(res.results),
                      page: res.page - 1,
                      totalCount: res.total,
                    });
                  })
                  .catch(reject);
              })
            }
            actions={[
              {
                icon: "add",
                tooltip: "Add Pin",
                position: "toolbar",
                onClick: () => {
                  history.push("/pins/add");
                },
              },

              (rowData) => ({
                icon: "edit",
                iconProps: { style: { color: "primary" }, fontSize: "small" },
                tooltip: "Update PIN",
                onClick: () => {
                  history.push(path + rowData.id);
                },
              }),
              (rowData) => ({
                icon: "delete",
                iconProps: { style: { color: "red" }, fontSize: "small" },
                tooltip: "Delete PIN",
                onClick: () => {
                  PinService.deletePin(rowData.id)
                    .then(() => {
                      tableRef.current && tableRef.current.onQueryChange();
                    })
                    .catch((e) => {
                      if (
                        e.response &&
                        e.response.data &&
                        e.response.data.error
                      ) {
                        notify(e.response.data.error);
                      }
                      tableRef.current && tableRef.current.onQueryChange();
                    });
                },
              }),

              (rowData) => ({
                icon: () => (
                  <PersonAddDisabledIcon
                    style={{ color: "#00acc1" }}
                    fontSize="small"
                  />
                ),
                tooltip: "Release PIN",
                onClick: () => {
                  PinService.releasePin(rowData.id)
                    .then(() => {
                      tableRef.current && tableRef.current.onQueryChange();
                    })
                    .catch((e) => {
                      if (
                        e.response &&
                        e.response.data &&
                        e.response.data.error
                      ) {
                        notify(e.response.data.error);
                      }
                    });
                  // fetchPins();
                },
              }),
            ]}
            components={{
              Toolbar: (props) => (
                <div style={{ alignItems: "center", paddingRight: "0%" }}>
                  <CardHeader color="primary" icon>
                    <CardIcon color="rose">
                      <FiberPinIcon />
                    </CardIcon>
                  </CardHeader>
                  <MTableToolbar {...props} />
                </div>
              ),
            }}
            options={{
              actionsColumnIndex: -1,
              debounceInterval: 500,
              sorting: true,
              pageSize: 10,
            }}
          />
        </Card>
      </GridItem>
    </GridContainer>
  );
}
