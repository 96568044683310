import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import DiscountService from "services/DiscountService";
import useAPI from "utils/useAPI";
import buttons from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";
import { useHistory } from "react-router-dom";
import tableStyles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import MaterialTable, { MTableToolbar } from "material-table";
import moment from "moment";
const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    buttons,
  },
  ...tableStyles,
};

const useStyles = makeStyles(styles);

export default function ReactTables() {
  const api = useAPI();
  const history = useHistory();
  let path = "/discount/edit/";
  const tableRef = React.createRef();

  const getDate = (dateTime) => {
    return moment(dateTime).format("MM/DD/YYYY hh:mm A");
  };

  function transformDiscount(discount) {
    let transformed_discount = discount;
    transformed_discount = transformed_discount.map((discount) => {
      return {
        id: discount.id,
        value: discount.value,
        code: discount.code,
        type: discount.type,
        // "parkingSession.user_id": transaction.parkingSession.user.name,
        start_time: getDate(discount.start_time),
        expire_time: getDate(discount.expire_time),
        new_signup: discount.new_signup === 1 ? "Yes" : "No",
        apply_once_per_customer:
          discount.apply_once_per_customer === 1 ? "Yes" : "No",
      };
    });
    return transformed_discount;
  }

  React.useEffect(() => {
    DiscountService.init(api);
  }, [api]);

  const classes = useStyles();
  return (
    <GridContainer>
      <div className={classes.cardContentLeft}></div>
      <GridItem xs={12}>
        <Card>
          <MaterialTable
            title="Discount"
            tableRef={tableRef}
            columns={[
              { title: "Code", field: "code" },
              { title: "Value", field: "value" },
              { title: "Type", field: "type" },
              { title: "Start time", field: "start_time" },
              { title: "Expiry Time", field: "expire_time" },
              { title: "New Signup Only", field: "new_signup" },
              { title: "Once Per Customer", field: "apply_once_per_customer" },
            ]}
            data={(query) =>
              new Promise((resolve, reject) => {
                DiscountService.getDiscounts(
                  query.pageSize,
                  query.page + 1,
                  query.search,
                  query.orderBy ? query.orderBy.field : null,
                  query.orderDirection
                )
                  .then((res) => {
                    resolve({
                      data: transformDiscount(res.results),
                      page: res.page - 1,
                      totalCount: res.total,
                    });
                  })
                  .catch(reject);
              })
            }
            actions={[
              {
                icon: "add",
                tooltip: "Add Discount",
                position: "toolbar",
                onClick: () => {
                  history.push("/discount/add");
                },
              },
              (rowData) => ({
                icon: "edit",
                iconProps: { fontSize: "small" },
                tooltip: "Update Discount PRomo",
                onClick: () => {
                  history.push(path + rowData.id);
                },
              }),
            ]}
            components={{
              Toolbar: (props) => (
                <div style={{ alignItems: "center", paddingRight: "0%" }}>
                  <CardHeader color="primary" icon>
                    <CardIcon color="rose">
                      <LocalAtmIcon />
                    </CardIcon>
                  </CardHeader>
                  <MTableToolbar {...props} />
                </div>
              ),
            }}
            options={{
              actionsColumnIndex: -1,
              debounceInterval: 500,
              filtering: false,
              sorting: true,
              pageSize: 10,
            }}
          />
        </Card>
      </GridItem>
    </GridContainer>
  );
}
