import Axios from "axios";

const requests = [];
const { CancelToken } = Axios;
let api = Axios;

const getDashboardData = async () => {
  try {
    let cancel;
    const response = await api.get("/super/dashboard", {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const init = async (axiosApi) => {
  api = axiosApi;
};

const DashboardService = {
  init,
  getDashboardData,
};

export default DashboardService;
