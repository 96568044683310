import Axios from "axios";

const requests = [];
const { CancelToken } = Axios;
let api = Axios;

const getLocations = async () => {
  try {
    let cancel;
    const response = await api.get("/locations", {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getLocationList = async (
  pageSize,
  page,
  search,
  orderBy,
  orderDirection
) => {
  try {
    let cancel;
    const response = await api.get(
      "/admin/location",
      { params: { pageSize, page, search, orderBy, orderDirection } },
      {
        cancelToken: new CancelToken((c) => {
          cancel = c;
          requests.push(cancel);
        }),
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const storeLocation = async (data) => {
  try {
    let cancel;
    const response = await api.post("/admin/location", data, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getLocation = async (id) => {
  try {
    let cancel;
    const response = await api.get(`/admin/location/${id}`, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const updateLocation = async (data) => {
  try {
    let cancel;
    const response = await api.put("/admin/location/", data, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const init = async (axiosApi) => {
  api = axiosApi;
};

const LocationService = {
  init,
  getLocations,
  getLocationList,
  storeLocation,
  getLocation,
  updateLocation,
};

export default LocationService;
