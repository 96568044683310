import Axios from "axios";

const requests = [];
const { CancelToken } = Axios;
let api = Axios;

const getPlans = async (pageSize, page, search, orderBy, orderDirection) => {
  try {
    let cancel;
    const response = await api.get(
      "/admin/subscription",
      { params: { pageSize, page, search, orderBy, orderDirection } },
      {
        cancelToken: new CancelToken((c) => {
          cancel = c;
          requests.push(cancel);
        }),
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const changeStatus = async (data) => {
  try {
    let cancel;
    const response = await api.put(
      `/admin/subscription/${data.id}/status`,
      data,
      {
        cancelToken: new CancelToken((c) => {
          cancel = c;
          requests.push(cancel);
        }),
      }
    );
    console.log("1111", response);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getPlan = async (id) => {
  try {
    let cancel;
    const response = await api.get(`/admin/subscription/${id}`, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const storePlan = async (data) => {
  console.log("!1111", data);

  try {
    let cancel;
    const response = await api.post("/admin/subscription", data, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const updatePlan = async (data) => {
  try {
    let cancel;
    const response = await api.put(`/admin/subscription/${data.id}`, data, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const init = async (axiosApi) => {
  api = axiosApi;
};

const SubscriptionService = {
  init,
  storePlan,
  getPlan,
  getPlans,
  updatePlan,
  changeStatus,
};

export default SubscriptionService;
