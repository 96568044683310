import Axios from "axios";

const requests = [];
const { CancelToken } = Axios;
let api = Axios;

const getPins = async (pageSize, page, q, orderBy, orderDirection) => {
  try {
    let cancel;
    const response = await api.get(
      "/admin/pins",
      { params: { pageSize, page, q, orderBy, orderDirection } },
      {
        cancelToken: new CancelToken((c) => {
          cancel = c;
          requests.push(cancel);
        }),
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getPin = async (id) => {
  try {
    let cancel;
    const response = await api.get(`/admin/pin/${id}`, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const storePin = async (data) => {
  try {
    let cancel;
    const response = await api.post("/admin/pins", data, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const updatePin = async (data) => {
  try {
    let cancel;
    const response = await api.put("/admin/pins", data, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const deletePin = async (id) => {
  try {
    let cancel;
    const response = await api.delete(`/admin/pins/${id}`, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const releasePin = async (id) => {
  let cancel;
  const response = await api.delete(`/admin/pins/${id}/release`, {
    cancelToken: new CancelToken((c) => {
      cancel = c;
      requests.push(cancel);
    }),
  });
  return response.data;
};

const init = async (axiosApi) => {
  api = axiosApi;
};

const PinService = {
  init,
  getPins,
  getPin,
  storePin,
  updatePin,
  deletePin,
  releasePin,
};

export default PinService;
