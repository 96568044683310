import Axios from "axios";

const requests = [];
const { CancelToken } = Axios;
let api = Axios;

const list = async (pageSize, page, search, orderBy, orderDirection) => {
  try {
    let cancel;
    const response = await api.get(
      "/admin/parking-sessions",
      { params: { pageSize, page, search, orderBy, orderDirection } },
      {
        cancelToken: new CancelToken((c) => {
          cancel = c;
          requests.push(cancel);
        }),
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getSessionDetail = async (id) => {
  const response = await api.get(`/admin/session-detail/${id}`, {
    cancelToken: new CancelToken((c) => {
      requests.push(c);
    }),
  });
  return response.data;
};
const applyDiscount = async (data) => {
  const response = await api.put(
    `/admin/parking-session/${data.parking_session_id}`,
    data,
    {
      cancelToken: new CancelToken((c) => {
        requests.push(c);
      }),
    }
  );
  return response.data;
};
const discountList = async () => {
  const response = await api.get(`/admin/discount`, {
    cancelToken: new CancelToken((c) => {
      requests.push(c);
    }),
  });
  return response.data;
};
const retrieveParkingSession = async (data) => {
  const response = await api.post(
    `admin/retrieve/${data.u_id}`,
    { sessionId: data.id },
    {
      cancelToken: new CancelToken((c) => {
        requests.push(c);
      }),
    }
  );
  return response.data;
};
const init = async (axiosApi) => {
  api = axiosApi;
};

const ParkingSessionService = {
  init,
  list,
  getSessionDetail,
  applyDiscount,
  discountList,
  retrieveParkingSession,
};

export default ParkingSessionService;
