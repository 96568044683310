import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import GridItem from "components/Grid/GridItem.js";
import {
  FormControl,
  TextField,
  Checkbox,
  FormControlLabel,
  FormLabel,
  FormGroup,
} from "@material-ui/core";

export default function Site({ id, data, updateSiteList }) {
  const [state, setState] = React.useState({
    siteName: "",
    parkingCarousel: true,
    monthlyMembership: false,
    payGo: false,
    ticketSystem: false,
  });
  const handleSiteName = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
    updateSiteList(id, state);
  };
  const handleChange = (event) => {
    console.log("222222", event.target.name, event.target.checked);
    setState({ ...state, [event.target.name]: event.target.checked });
    updateSiteList(id, state);
  };
  return (
    // <span className={classes.badge + " " + classes[color]}>{children}</span>
    <>
      <GridItem xs={9} sm={9} display="flex" flexDirection="row">
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Site Name"
          fullWidth
          // value={state.siteName}
          inputProps={{
            onChange: (event) => {
              handleSiteName(event);
              console.log("state", state);
            },
          }}
          variant="outlined"
          type="text"
          // success={this.state.nameState === "success"}
          // error={this.state.nameState === "error"}
        />
      </GridItem>
      <GridItem xs={9} sm={9}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Parking Site Type:</FormLabel>
          <FormGroup aria-label="position" row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.parkingCarousel}
                  onChange={handleChange}
                  name="parkingCarousel"
                  color="secondary"
                />
              }
              label="Parking Carousel"
            />
          </FormGroup>
        </FormControl>
      </GridItem>
      <GridItem>
        <FormControl component="fieldset">
          <FormLabel component="legend">Payment Method:</FormLabel>
          <FormGroup aria-label="position" row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.monthlyMembership}
                  onChange={handleChange}
                  name="monthlyMembership"
                  color="secondary"
                />
              }
              label="Monthly Membership"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.payGo}
                  onChange={handleChange}
                  name="payGo"
                  color="secondary"
                />
              }
              label="Pay/Go"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.ticketSystem}
                  onChange={handleChange}
                  name="ticketSystem"
                  color="secondary"
                />
              }
              label="With Parking Attendant (Ticketing System)"
            />
          </FormGroup>
        </FormControl>
      </GridItem>
    </>
  );
}

Site.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "warning",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  children: PropTypes.node,
  id: PropTypes.string,
  data: PropTypes.object,
};

Site.defaultProps = {
  id: null,
  data: {
    siteName: "",
    parkingCarousel: true,
    monthlyMembership: false,
    payGo: false,
    ticketSystem: false,
  },
};
