import React from "react";
import MUIDataTable from "mui-datatables";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip, IconButton, Typography } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import TransactionService from "services/TransactionService";
import useAPI from "utils/useAPI";
import MaterialTable, { MTableToolbar } from "material-table";
import moment from "moment";
import ReceiptIcon from "@material-ui/icons/Receipt";
import AssignmentReturnIcon from "@material-ui/icons/AssignmentReturn";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";

const useStyles = makeStyles((theme) => ({
  ...styles,
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export default function Transactions() {
  const api = useAPI();
  const classes = useStyles();
  const [data, setData] = React.useState(null);

  const getDate = (dateTime) => {
    // return new Date(dateTime);
    return moment(dateTime).format("L");
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const viewInvoice = (transaction) => {
    TransactionService.getInvoiceLink(transaction).then((result) => {
      openInNewTab(result);
    });
  };

  const refundTransaction = async (transaction) => {
    TransactionService.refundTransaction(transaction).then((result) => {
      console.log(result);
    });
  };

  function transformTransaction(transaction) {
    let transformed_transaction = transaction;
    console.log(transaction);
    transformed_transaction = transformed_transaction.map((transaction) => {
      return {
        id: transaction.id,
        stripe_payment_intent_id: transaction.stripe_payment_intent_id,
        "parkingSession.user_id": transaction.parkingSession.user.name,
        "parkingSession.discount_id": transaction.parkingSession.discount
          ? transaction.parkingSession.discount.type === "Percentage"
            ? transaction.parkingSession.discount.value + "%"
            : transaction.parkingSession.discount.value + "$"
          : "NA",
        amount: `${transaction.price}`,
        duration: transaction.parkingSession.duration
          ? transaction.parkingSession.duration
          : "NA",
        pin: transaction.parkingSession.user_vehicle_pin,
        date: getDate(transaction.parkingSession.start_time),
        vehicle: transaction.parkingSession.vehicle_name,
        status: String(transaction.status).toUpperCase(),
        stripe_refund_id: transaction.stripe_refund_id,
        actions: (
          <>
            <div display="flex">
              <Tooltip title="View Invoice" aria-label="">
                <IconButton
                  onClick={() => {
                    viewInvoice(transaction.stripe_payment_intent_id);
                  }}
                >
                  <ReceiptIcon style={{ color: "#0C3C78" }} />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={
                  transaction.stripe_refund_id ? "Refunded" : "Make Refund"
                }
                aria-label=""
              >
                <span>
                  <IconButton
                    onClick={async () => {
                      refundTransaction({
                        id: transaction.id,
                        payment_intent: transaction.stripe_payment_intent_id,
                        amount: transaction.amount,
                      });
                    }}
                    disabled={transaction.stripe_refund_id ? true : false}
                  >
                    <AssignmentReturnIcon
                      color={
                        transaction.stripe_refund_id ? "secondary" : "primary"
                      }
                    />
                  </IconButton>
                </span>
              </Tooltip>
            </div>
          </>
        ),
      };
    });
    return transformed_transaction;
  }

  const fetchTransaction = () => {
    TransactionService.getTransactions()
      .then((res) => {
        let d = transformTransaction(res.results);
        setData(d);
      })
      .catch();
  };
  React.useEffect(() => {
    TransactionService.init(api);
    fetchTransaction();
  }, [api]);

  const columns = [
    {
      label: "Name",
      name: "parkingSession.user_id",
    },
    {
      label: "Amount",
      name: "amount",
    },
    {
      label: "Duration",
      name: "duration",
    },
    {
      label: "Date",
      name: "date",
    },
    {
      label: "Vehicle",
      name: "vehicle",
    },
    {
      label: "Pin",
      name: "pin",
    },
    {
      label: "Status",
      name: "status",
    },
    {
      label: "Discount",
      name: "parkingSession.discount_id",
    },
    {
      label: "Actions",
      name: "actions",
    },
  ];
  const options = {
    filter: false, // Hide filters
    print: false, // Hide print
    download: false, // Hide download
    selectableRowsHeader: false, // Hide checkbox in header
    selectableRowsHideCheckboxes: true, // Hide checkbox for every row
  };
  return (
    <GridContainer>
      <div className={classes.cardContentLeft}></div>
      <GridItem xs={12}>
        <Card>
          {data && (
            <MUIDataTable
              title={
                <div>
                  <CardIcon color="rose">
                    <MonetizationOnIcon style={{ color: "white" }} />{" "}
                  </CardIcon>
                  <Typography variant="h6">Transactions</Typography>
                </div>
              }
              data={data}
              columns={columns}
              options={options}
            />
          )}
        </Card>
      </GridItem>
    </GridContainer>
  );
}
