/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import Radio from "@material-ui/core/Radio";
import { Autocomplete } from "@material-ui/lab";
import RadioGroup from "@material-ui/core/RadioGroup";
import RoomOutlinedIcon from "@material-ui/icons/RoomOutlined";
// core components
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import { useHistory } from "react-router-dom";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import UserService from "services/UserService";
import CarouselService from "services/CarouselService";
import VehicleService from "services/VehicleService";
import PinService from "services/PinService";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import useAPI from "utils/useAPI";
const useStyles = makeStyles(styles);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function PinForm({ id = null }) {
  const [isEdit] = React.useState(id ? true : false);
  const [pinLabel] = React.useState(id ? "Update Pin" : "Add Pin");

  const [pin, setPin] = React.useState({
    plc_pin: "",
    user_id: "",
    carousel_id: "",
    palette_number: "",
  });

  const api = useAPI();
  const history = useHistory();
  const [users, setUsers] = React.useState([]);
  const [carousel, setCarousel] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  const [errors, setErrors] = React.useState({
    plc_pin: "",
    user_id: "",
    carousel_id: "",
    palette_number: "",
  });

  const fetchPin = async () => {
    await PinService.getPin(id).then((res) => {
      const respPin = [];
      Object.keys(res).forEach((key) => {
        respPin[key] = res[key] ? res[key] : "";
      });
      setPin(respPin);
    });
  };

  const fetchUser = async () => {
    await UserService.getUsers().then((res) => {
      setUsers(res.results);
    });
  };

  const fetchCarousel = async () => {
    await CarouselService.getCarousels().then((res) => {
      setCarousel(res.results);
    });
  };

  React.useEffect(() => {
    UserService.init(api);
    VehicleService.init(api);
    PinService.init(api);
    CarouselService.init(api);
    fetchUser();
    fetchCarousel();
    if (id) {
      fetchPin();
    }
  }, [api]);

  const verifyNumber = (value) => {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  };

  const handleChange = (prop, val) => {
    setPin({ ...pin, [prop]: val });
    if (verifyLength(val, 1)) {
      setErrors({ ...errors, [prop]: "success" });
    } else {
      setErrors({ ...errors, [prop]: "error" });
    }
  };

  // function that verifies if a string has a given length or not
  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  const handleExistPin = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const registerClick = async () => {
    let error = 0;
    let newErrors = errors;
    ["plc_pin", "palette_number"].forEach((field) => {
      if (pin[field] === "") {
        newErrors[field] = "error";
        error = 1;
      }
    });

    ["plc_pin", "palette_number"].forEach((field) => {
      if (!verifyNumber(pin[field])) {
        newErrors[field] = "error";
        error = 1;
      }
    });

    setErrors({ ...newErrors });

    if (error) return;

    let res;
    if (id) {
      res = await PinService.updatePin(pin);
    } else {
      res = await PinService.storePin(pin);
    }
    if (!res) handleExistPin();
    else history.push("/admin/pins");
  };
  const classes = useStyles();
  return (
    <GridContainer>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="warning">
          Plc pin is already registered , please choose another
        </Alert>
      </Snackbar>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <RoomOutlinedIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{pinLabel}</h4>
          </CardHeader>
          <CardBody>
            <form>
              <TextField
                error={errors.plc_pin === "error"}
                margin="dense"
                id="plcpin"
                label="Plc Pin *"
                variant="outlined"
                type="text"
                disabled={!!id}
                fullWidth
                value={pin.plc_pin}
                onChange={(event) => {
                  if (verifyNumber(event.target.value)) {
                    setErrors({ ...errors, plc_pin: "success" });
                  } else {
                    setErrors({ ...errors, plc_pin: "error" });
                  }
                  handleChange("plc_pin", event.target.value);
                }}
              />

              <TextField
                error={errors.palette_number === "error"}
                margin="dense"
                id="pelleteNumber"
                label="Pellete Number *"
                variant="outlined"
                type="text"
                fullWidth
                value={pin.palette_number}
                onChange={(event) => {
                  handleChange("palette_number", event.target.value);
                }}
              />

              <FormControl
                fullWidth
                variant="outlined"
                margin="dense"
                className={classes.selectFormControl}
              >
                <InputLabel
                  htmlFor="simple-select"
                  className={classes.selectLabel}
                >
                  Choose User
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={pin.user_id}
                  onChange={(e) => {
                    handleChange("user_id", e.target.value);
                  }}
                  error={errors.user_id === "error"}
                  inputProps={{
                    name: "simpleSelect",
                    id: "simple-select",
                  }}
                  label="Choose User"
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Choose User
                  </MenuItem>

                  {users.map((user, index) => (
                    <MenuItem
                      value={`${user.id}`}
                      key={`${user.id}`}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                    >
                      {`${user.name}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl
                fullWidth
                variant="outlined"
                margin="dense"
                className={classes.selectFormControl}
              >
                <InputLabel
                  htmlFor="simple-select"
                  className={classes.selectLabel}
                >
                  Choose carousel
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={pin.carousel_id}
                  onChange={(e) => {
                    handleChange("carousel_id", e.target.value);
                  }}
                  inputProps={{
                    name: "simpleSelect",
                    id: "simple-select",
                  }}
                  label="Choose carousel"
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Choose carousel *
                  </MenuItem>

                  {carousel &&
                    carousel.map((carousel, index) => (
                      <MenuItem
                        value={`${carousel.id}`}
                        key={`${carousel.id}`}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                      >
                        {`${carousel.nick_name} - ${carousel.ip_address}`}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <div className={classes.formCategory}>
                <small>*</small> Required fields
              </div>

              <Button
                color="rose"
                onClick={registerClick}
                className={classes.registerButton}
              >
                {pinLabel}
              </Button>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

PinForm.propTypes = {
  id: PropTypes.string,
};

PinForm.defaultProps = {
  id: null,
};

export default PinForm;
