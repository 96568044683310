import React from "react";
import PropTypes from "prop-types";
// @material-ui/icons

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { TextField } from "@material-ui/core";
import InputMask from "react-input-mask";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  inputAdornment: {
    position: "relative",
  },
};

class Step1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      nameState: "",
      username: "",
      usernameState: "",
      email: "",
      emailState: "",
      phone: "",
      phoneState: "",
      password: "",
      passwordState: "",
    };
  }
  sendState() {
    return this.state;
  }
  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }
  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }
  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case "email":
        if (this.verifyEmail(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  }
  isValidated() {
    if (
      this.state.nameState === "success" &&
      this.state.usernameState === "success" &&
      this.state.emailState === "success" &&
      this.state.phoneState === "success" &&
      this.state.passwordState === "success"
    ) {
      return true;
    } else {
      if (this.state.nameState !== "success") {
        this.setState({ nameState: "error" });
      }
      if (this.state.usernameState !== "success") {
        this.setState({ usernameState: "error" });
      }
      if (this.state.emailState !== "success") {
        this.setState({ emailState: "error" });
      }
      if (this.state.phoneState !== "success") {
        this.setState({ phoneState: "error" });
      }
      if (this.state.passwordState !== "success") {
        this.setState({ passwordState: "error" });
      }
    }
    return false;
  }
  render() {
    const { classes } = this.props;
    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12}>
          <h4 className={classes.infoText}>Account Information</h4>
        </GridItem>
        {/* <GridItem xs={12} sm={4}>
          <PictureUpload />
        </GridItem> */}
        <GridItem xs={12} sm={8}>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            formControlProps={{
              fullWidth: true,
            }}
            fullWidth
            inputProps={{
              onChange: (event) => this.change(event, "name", "length", 1),
            }}
            variant="outlined"
            type="text"
            success={this.state.nameState === "success"}
            error={this.state.nameState === "error"}
          />

          <TextField
            autoFocus
            margin="dense"
            id="username"
            label="Username"
            formControlProps={{
              fullWidth: true,
            }}
            fullWidth
            inputProps={{
              onChange: (event) => this.change(event, "username", "length", 1),
            }}
            variant="outlined"
            type="text"
            success={this.state.usernameState === "success"}
            error={this.state.usernameState === "error"}
          />

          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email"
            fullWidth
            inputProps={{
              onChange: (event) => this.change(event, "email", "email"),
            }}
            variant="outlined"
            type="text"
            success={this.state.emailState === "success"}
            error={this.state.emailState === "error"}
          />
          {/* <TextField
            autoFocus
            margin="dense"
            id="phone"
            label="Phone"
            fullWidth
            inputProps={{
              onChange: (event) => this.change(event, "phone", "length", 16),
            }}
            variant="outlined"
            type="tel"
            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
            success={this.state.phoneState === "success"}
            error={this.state.phoneState === "error"}
          /> */}
          <InputMask
            mask="+1 (999) 999 9999"
            // value={values.phone}
            disabled={false}
            // onChange={handleChange("phone")}
            onChange={(event) => this.change(event, "phone", "length", 17)}
            maskChar=" "
          >
            {() => (
              <TextField
                error={this.state.phoneState === "error"}
                autoFocus
                margin="dense"
                id="phone"
                label="Phone"
                variant="outlined"
                type="text"
                fullWidth
              />
            )}
          </InputMask>

          <TextField
            autoFocus
            margin="dense"
            id="password"
            label="Password"
            fullWidth
            inputProps={{
              onChange: (event) => this.change(event, "password", "length", 6),
            }}
            variant="outlined"
            type="password"
            success={this.state.passwordState === "success"}
            error={this.state.passwordState === "error"}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

Step1.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(style)(Step1);
