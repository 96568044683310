import React from "react";
// @material-ui/core components
// import { makeStyles } from "@material-ui/core/styles";

// material-ui icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import ListAltIcon from "@material-ui/icons/ListAlt";
// apis
import CarouselService from "services/CarouselService";
import Button from "components/CustomButtons/Button.js";
import useAPI from "utils/useAPI";
import { useHistory } from "react-router-dom";
import MaterialTable, { MTableToolbar } from "material-table";
import ReactJson from "react-json-view";

import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.js";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(modalStyle);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function Carousels() {
  const api = useAPI();
  const history = useHistory();
  const classes = useStyles();

  const [modal, setModal] = React.useState(false);
  const [statisticsTitle, setStatisticsTitle] = React.useState("");
  const [jsonObject, setJsonObject] = React.useState({});
  let path = "/carousel/edit/";
  const tableRef = React.createRef();

  React.useEffect(() => {
    CarouselService.init(api);
  }, [api]);

  function transform(data) {
    let transformed_data = data;
    transformed_data = transformed_data.map((carousel) => {
      return {
        id: carousel.id,
        ip_address: carousel.ip_address,
        nick_name: carousel.nick_name,
        statistics: (
          <>
            {" "}
            <div display="flex">
              <Button
                color="rose"
                round
                onClick={() => {
                  CarouselService.getCarouselStatus(carousel.id).then(
                    (response) => {
                      setJsonObject(response);
                      setStatisticsTitle("Status Data");
                      setModal(true);
                    }
                  );
                }}
              >
                Status
              </Button>
              <Button
                color="rose"
                round
                onClick={() => {
                  CarouselService.getProxyCarousel(carousel.id).then(
                    (response) => {
                      setJsonObject(response);
                      setStatisticsTitle("Pallet Data");
                      setModal(true);
                    }
                  );
                }}
              >
                Location
              </Button>
            </div>
          </>
        ),
        qrCodeUrl: "/carousel/" + carousel.token,
      };
    });
    return transformed_data;
  }
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <Dialog
            classes={{
              root: classes.center,
              paper: classes.modal,
            }}
            open={modal}
            scroll="body"
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setModal(false)}
            aria-labelledby="modal-slide-title"
            aria-describedby="modal-slide-description"
          >
            <DialogTitle
              id="classic-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <IconButton
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={() => setModal(false)}
              >
                <Close className={classes.modalClose} />
              </IconButton>
              <h4 className={classes.modalTitle}>{statisticsTitle}</h4>
            </DialogTitle>
            <DialogContent
              id="modal-slide-description"
              className={classes.modalBody}
            >
              <ReactJson src={jsonObject} />
            </DialogContent>
            <DialogActions
              className={classes.modalFooter + " " + classes.modalFooterCenter}
            >
              {/* <Button onClick={() => setModal(false)} color="rose">
                Close
              </Button> */}
            </DialogActions>
          </Dialog>
          <MaterialTable
            title="Carousels"
            tableRef={tableRef}
            columns={[
              { title: "#", field: "id" },
              { title: "Ip Address", field: "ip_address" },
              { title: "Name", field: "nick_name" },
              { title: "Statistics", field: "statistics" },
            ]}
            data={(query) =>
              new Promise((resolve, reject) => {
                CarouselService.getCarousels(
                  query.pageSize,
                  query.page + 1,
                  query.search
                )
                  .then((res) => {
                    resolve({
                      data: transform(res.results),
                      page: res.page - 1,
                      totalCount: res.total,
                    });
                  })
                  .catch(reject);
              })
            }
            actions={[
              {
                icon: "add",
                tooltip: "Add Carousel",
                position: "toolbar",
                isFreeAction: true,
                onClick: () => {
                  history.push("/admin/add-carousel");
                },
              },

              (rowData) => ({
                icon: "edit",
                iconProps: { fontSize: "small" },
                tooltip: "Update Carousel",
                onClick: () => {
                  history.push(path + rowData.id);
                },
              }),
              (rowData) => ({
                icon: "delete",
                iconProps: { fontSize: "small", style: { color: "red" } },
                tooltip: "Delete Carousel",
                onClick: async () => {
                  await CarouselService.deleteCarousel(rowData.id);
                  tableRef.current && tableRef.current.onQueryChange();
                },
              }),
              (rowData) => ({
                icon: "link",
                iconProps: { fontSize: "small" },
                tooltip: "Open QR Code",
                onClick: () => {
                  window.open(rowData.qrCodeUrl);
                },
              }),
            ]}
            components={{
              Toolbar: (props) => (
                <div style={{ alignItems: "center", paddingRight: "0%" }}>
                  <CardHeader color="primary" icon>
                    <CardIcon color="rose">
                      <ListAltIcon />
                    </CardIcon>
                  </CardHeader>
                  <MTableToolbar {...props} />
                </div>
              ),
            }}
            options={{
              actionsColumnIndex: -1,
              debounceInterval: 500,
              pageSize: 10,
            }}
          />
        </Card>
      </GridItem>
    </GridContainer>
  );
}
