import Axios from "axios";

const requests = [];
const { CancelToken } = Axios;
let api = Axios;

const getOrganization = async (
  pageSize,
  page,
  search,
  orderBy,
  orderDirection
) => {
  try {
    let cancel;
    const response = await api.get(
      "/admin/organization",
      { params: { pageSize, page, search, orderBy, orderDirection } },
      {
        cancelToken: new CancelToken((c) => {
          cancel = c;
          requests.push(cancel);
        }),
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
const storeOrganization = async (data) => {
  try {
    let cancel;
    const response = await api.post("/admin/organization", data, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const init = async (axiosApi) => {
  api = axiosApi;
};

const VehicleService = {
  init,
  getOrganization,
  storeOrganization,
};

export default VehicleService;
