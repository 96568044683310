import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import CreateIcon from "@material-ui/icons/Create";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { FormControl, TextField } from "@material-ui/core";
import FiberPinIcon from "@material-ui/icons/FiberPin";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import useAPI from "utils/useAPI";
import CarouselService from "services/CarouselService";
import LocationService from "services/LocationService";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import MaterialTable, { MTableToolbar } from "material-table";

const useStyles = makeStyles(styles);

export default function RegularForms() {
  const classes = useStyles();
  const history = useHistory();
  const api = useAPI();
  let params = useParams();
  const [carousel, setCarousel] = React.useState("");
  const [locationList, setLocationList] = React.useState([]);
  const [pinGrid, setPinGrid] = React.useState([]);
  const [userLocation, setUserLocation] = React.useState("");
  const fetchCarousel = async () => {
    await CarouselService.getCarousel(params.id).then((res) => {
      console.log("HereeeE?EE?E/e/e?e/eeE//e/ee", res.pins);
      setCarousel(res);
      //
      setUserLocation(res.location_id);

      setPinGrid(
        res.pins.map((pin) => {
          return {
            id: pin.id,
            user_vehicle_pin: pin.user_vehicle_pin,
            plc_pin: pin.plc_pin,
            palette_number: pin.palette_number,
            available: pin.available ? "Yes" : "No",
            user: pin.users ? pin.users.name : "NA",
          };
        })
      );
    });
  };

  const fetchLocationList = async () => {
    await LocationService.getLocations().then((res) => {
      setLocationList(
        res.map((item) => {
          return {
            id: item.id,
            name: item.name + " - " + item.zipcode,
            value: item.id,
          };
        })
      );
      if (res.length === 1) {
        setUserLocation(res[0].id);
      }
    });
  };

  React.useEffect(() => {
    CarouselService.init(api);
    LocationService.init(api);
    fetchCarousel();
    fetchLocationList();
  }, [api]);
  const handleChange = (prop, event) => {
    setCarousel({ ...carousel, [prop]: event });
  };
  const [ipAddress, setIpAddress] = React.useState("");

  const handleLocationChange = (prop, event) => {
    setCarousel({ ...carousel, [prop]: event });
    setUserLocation(event);
  };
  const handleSave = async () => {
    if (carousel["ip_address"] && carousel["ip_address"] !== "") {
      await CarouselService.updateCarousel(carousel);
      let path = `/admin/carousels`;
      history.push(path);
    } else {
      setIpAddress("error");
    }
  };
  const cancelRoute = () => {
    let path = `/admin/carousels`;
    history.push(path);
  };
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={6}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <CreateIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Update Carousel</h4>
          </CardHeader>
          <CardBody>
            <form>
              <FormControl
                variant="outlined"
                fullWidth
                margin="dense"
                className={classes.formControl}
              >
                <TextField
                  // error={errors.plate === "error"}
                  autoFocus
                  margin="dense"
                  id="ip_adress"
                  label="API Url"
                  error={ipAddress === "error"}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (event) => {
                      handleChange("ip_address", event.target.value);
                    },
                    value: `${carousel.ip_address}`,
                  }}
                  variant="outlined"
                  type="text"
                />

                <TextField
                  // error={errors.plate === "error"}
                  autoFocus
                  margin="dense"
                  id="nick_name"
                  label="Name"
                  // error={ipAddress === "error"}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (event) => {
                      handleChange("nick_name", event.target.value);
                    },
                    value: `${carousel.nick_name}`,
                  }}
                  variant="outlined"
                  type="text"
                />

                <TextField
                  // error={errors.plate === "error"}
                  autoFocus
                  margin="dense"
                  id="description"
                  label="Description"
                  // error={ipAddress === "error"}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (event) => {
                      handleChange("description", event.target.value);
                    },
                    value: carousel.description || "",
                  }}
                  variant="outlined"
                  type="text"
                />

                <FormControl
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  className={classes.formControl}
                >
                  <InputLabel>Location</InputLabel>
                  <Select
                    fullWidth
                    label="Location"
                    value={userLocation}
                    required
                    onChange={(e) => {
                      handleLocationChange("location_id", e.target.value);
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      Choose Location *
                    </MenuItem>

                    {locationList &&
                      locationList.map((location) => (
                        <MenuItem
                          value={`${location.id}`}
                          key={`${location.id}`}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                        >
                          {`${location.name}`}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </FormControl>
              {/* <Button color="white" onClick={handleValue}>
                Reset
              </Button> */}
              <Button color="success" onClick={handleSave}>
                Update
              </Button>
              <Button color="danger" onClick={cancelRoute}>
                Cancel
              </Button>
            </form>
          </CardBody>
        </Card>
      </GridItem>

      <GridItem xs={12}>
        <Card>
          <MaterialTable
            title="carousel Pins List"
            columns={[
              { title: "User Vehicle Pin", field: "user_vehicle_pin" },
              { title: "PLC PIN", field: "plc_pin" },
              { title: "Pallete Number", field: "palette_number" },
              { title: "Available", field: "available", sorting: false },
              { title: "User", field: "user", sorting: false },
            ]}
            data={pinGrid}
            actions={[]}
            components={{
              Toolbar: (props) => (
                <div style={{ alignItems: "center", paddingRight: "0%" }}>
                  <CardHeader color="primary" icon>
                    <CardIcon color="rose">
                      <FiberPinIcon />
                    </CardIcon>
                  </CardHeader>
                  <MTableToolbar {...props} />
                </div>
              ),
            }}
            options={{
              actionsColumnIndex: -1,
              pageSize: 10,
            }}
          />
        </Card>
      </GridItem>
    </GridContainer>
  );
}
