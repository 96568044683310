/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import { useHistory } from "react-router-dom";
import CardIcon from "components/Card/CardIcon.js";
import { FormControl, TextField } from "@material-ui/core";
import CardBody from "components/Card/CardBody.js";
import LocationService from "services/LocationService";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PropTypes from "prop-types";
// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import useAPI from "utils/useAPI";
const useStyles = makeStyles(styles);

function LocationForm({ id = null }) {
  var numberRex = new RegExp("^[0-9]+$");
  var latlongregex = new RegExp("^(-?d+(.d+)?),s*(-?d+(.d+)?)$");
  const [users, setUsers] = React.useState([]);
  const [isEdit] = React.useState(id ? true : false);
  const [LocationLabel] = React.useState(
    id ? "Update Location" : "Add Location"
  );
  const [render, setRender] = React.useState(null);
  const [location, setLocation] = React.useState({
    name: "",
    latitude: "",
    longitude: "",
    zipcode: "",
    available_space: "",
  });
  const api = useAPI();
  const history = useHistory();

  const [errors, setErrors] = React.useState({
    name: "",
    latitude: "",
    longitude: "",
    zipcode: "",
    available_space: "",
  });

  const fetchLocation = async () => {
    await LocationService.getLocation(id).then((res) => {
      console.log("RES", res);
      setLocation(res);
      setRender(true);
    });
  };

  React.useEffect(() => {
    LocationService.init(api);
    if (id) fetchLocation();
  }, [api]);

  const handleChange = (prop, val) => {
    console.log("Changed", prop, val);
    setFee({ ...fee, [prop]: val });
    if (verifyLength(val, 1)) {
      setErrors({ ...errors, [prop]: "success" });
    } else {
      setErrors({ ...errors, [prop]: "error" });
    }
  };

  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  const verifyNumber = (value) => {
    if (numberRex.test(value) && value.length >= 1) {
      return true;
    }
    return false;
  };

  const registerClick = async () => {
    let error = 0;
    let newErrors = errors;
    ["name", "latitude", "longitude", "zipcode", "available_space"].forEach(
      (field) => {
        if (location[field] === "") {
          newErrors[field] = "error";
          error = 1;
        }
      }
    );
    setErrors({ ...newErrors });

    if (error) return;

    if (id) {
      await LocationService.updateLocation(location);
    } else await LocationService.storeLocation(location);
    history.push("/admin/location");
  };
  const classes = useStyles();
  return (
    <GridContainer>
      {((id && render) || !id) && (
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <LocationOnIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{LocationLabel}</h4>
            </CardHeader>
            <CardBody>
              <form>
                <TextField
                  error={errors.name === "error"}
                  margin="dense"
                  id="name"
                  label="Name *"
                  variant="outlined"
                  type="text"
                  fullWidth
                  value={location.name}
                  onChange={(event) => {
                    if (verifyLength(event.target.value, 1)) {
                      setErrors({ ...errors, name: "success" });
                    } else {
                      setErrors({ ...errors, name: "error" });
                    }
                    setLocation({
                      ...location,
                      name: event.target.value,
                    });
                  }}
                />

                <TextField
                  error={errors.latitude === "error"}
                  margin="dense"
                  id="latitude"
                  label="Latitude *"
                  variant="outlined"
                  type="text"
                  fullWidth
                  value={location.latitude}
                  onChange={(event) => {
                    if (verifyLength(event.target.value, 1)) {
                      setErrors({ ...errors, latitude: "success" });
                    } else {
                      setErrors({ ...errors, latitude: "error" });
                    }
                    setLocation({
                      ...location,
                      latitude: event.target.value,
                    });
                  }}
                />

                <TextField
                  error={errors.longitude === "error"}
                  margin="dense"
                  id="longitude"
                  label="Longitude *"
                  variant="outlined"
                  type="text"
                  fullWidth
                  value={location.longitude}
                  onChange={(event) => {
                    if (verifyLength(event.target.value, 1)) {
                      setErrors({ ...errors, longitude: "success" });
                    } else {
                      setErrors({ ...errors, longitude: "error" });
                    }
                    setLocation({
                      ...location,
                      longitude: event.target.value,
                    });
                  }}
                />

                <TextField
                  error={errors.zipcode === "error"}
                  margin="dense"
                  id="zip"
                  label="Zipcode *"
                  variant="outlined"
                  type="number"
                  fullWidth
                  value={location.zipcode}
                  onChange={(event) => {
                    if (verifyNumber(event.target.value)) {
                      setErrors({ ...errors, zipcode: "success" });
                    } else {
                      setErrors({ ...errors, zipcode: "error" });
                    }
                    setLocation({
                      ...location,
                      zipcode: event.target.value,
                    });
                  }}
                />

                <TextField
                  error={errors.available_space === "error"}
                  margin="dense"
                  id="available_space"
                  label="Space Available *"
                  variant="outlined"
                  type="number"
                  fullWidth
                  value={location.available_space}
                  onChange={(event) => {
                    if (verifyNumber(event.target.value)) {
                      setErrors({ ...errors, available_space: "success" });
                    } else {
                      setErrors({ ...errors, available_space: "error" });
                    }
                    setLocation({
                      ...location,
                      available_space: event.target.value,
                    });
                  }}
                />

                <div className={classes.formCategory}>
                  <small>*</small> Required fields
                </div>

                <Button
                  color="rose"
                  onClick={registerClick}
                  className={classes.registerButton}
                >
                  {LocationLabel}
                </Button>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      )}
    </GridContainer>
  );
}

LocationForm.propTypes = {
  id: PropTypes.string,
};

LocationForm.defaultProps = {
  id: null,
};

export default LocationForm;
