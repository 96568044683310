import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// core components
import useAPI from "utils/useAPI";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { useHistory } from "react-router-dom";
import Place from "@material-ui/icons/Place";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import priceImage1 from "assets/img/card-2.jpeg";
import priceImage2 from "assets/img/header-doc.jpeg";
import priceImage3 from "assets/img/card-1.jpeg";

import SuperAdminDashboard from "../../services/SuperAdminDashboardService";
const useStyles = makeStyles(styles);

export default function MainDashboard() {
  const classes = useStyles();
  const api = useAPI();
  const [organizations, setOrganizations] = React.useState([
    {
      id: 1,
      name: "Organization 1",
      description: "Organization 1 Description",
      state: "Washington",
      img: priceImage1,
    },
    {
      id: 2,
      name: "Organization 2",
      description: "Organization 2 Description",
      state: "Texas",
      img: priceImage2,
    },
    {
      id: 3,
      name: "Organization 3",
      description: "Organization 3 Description",
      state: "California",
      img: priceImage3,
    },
  ]);
  const history = useHistory();

  const fetchDashboardData = async () => {
    SuperAdminDashboard.getDashboardData().then((res) => {
      setOrganizations(res);
    });
  };
  React.useEffect(() => {
    SuperAdminDashboard.init(api);
    fetchDashboardData();
  }, [api]);
  return (
    <div>
      <h3>
        <center>Manage Organizations</center>
      </h3>
      <br />
      <GridContainer>
        {organizations &&
          organizations.map((item) => (
            <GridItem xs={12} sm={12} md={4} key={`${item.id}`}>
              <Card product className={classes.cardHover}>
                <CardHeader image className={classes.cardHeaderHover}>
                  <a
                    href="#pablo"
                    onClick={(e) =>
                      console.log("here", `${item.id}`, e.target.value)
                    }
                  >
                    <img src={item.img} alt="..." />
                  </a>
                </CardHeader>
                <CardBody>
                  <div className={classes.cardHoverUnder}></div>
                  <h4 className={classes.cardProductTitle}>
                    <a
                      href="#pablo"
                      onClick={(e) =>
                        console.log("here", `${item.id}`, e.target.value)
                      }
                    >
                      {item.name}
                    </a>
                  </h4>
                  <p className={classes.cardProductDesciprion}>
                    {item.description}
                  </p>
                </CardBody>
                <CardFooter product>
                  <div className={classes.price}></div>
                  <div className={`${classes.stats} ${classes.productStats}`}>
                    <Place /> {item.state}, USA
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
          ))}
      </GridContainer>
    </div>
  );
}
