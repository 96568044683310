/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React from "react";
import { Route } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "components/Loading";
import useAPI from "../../utils/useAPI";
import ErrorPage from "../../views/Pages/ErrorPage";

window.OneSignal = window.OneSignal || [];
const OneSignal = window.OneSignal;

const ProtectedRoute = ({ component, ...args }) => {
  const api = useAPI();
  const [role, setRole] = React.useState(null);
  const fetchUsers = async () => {
    await api.get("/user").then((res) => {
      setRole(res.data.role);
    });
  };

  const setToken = async () => {
    await api.get("/user").then((res) => {
      OneSignal.push(() => {
        OneSignal.init({
          appId:
            process.env.REACT_APP_ONESIGNAL_APP_ID ||
            "2038a34b-7a4a-454e-a48f-435a233b15d1",
          safari_web_id:
            process.env.REACT_APP_ONESIGNAL_SAFARI_APP_ID ||
            "web.onesignal.auto.456a4abd-b5a3-4365-958f-9af6b49613be",
        });
        OneSignal.setExternalUserId(`${res.data.id}`);
        OneSignal.getUserId((userId) => {
          console.log("OneSignal User ID:", userId);
          api
            .post("/admin/set-token", {
              token: userId,
            })
            .then((resp) => console.log(resp))
            .catch((err) => console.error(err));
        });
      });
    });
  };

  React.useEffect(() => {
    setToken();
  }, []);

  React.useEffect(() => {
    fetchUsers();
  }, [role]);

  return role ? (
    <Route
      component={withAuthenticationRequired(
        (role === "admin" && component) || ErrorPage,
        {
          onRedirecting: () => <Loading />,
        }
      )}
      {...args}
    />
  ) : (
    <Route
      component={withAuthenticationRequired(Loading, {
        onRedirecting: () => <Loading />,
      })}
      {...args}
    />
  );
};

export default ProtectedRoute;
