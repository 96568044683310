import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import CreateIcon from "@material-ui/icons/Create";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { FormControl, TextField } from "@material-ui/core";

// import { BrowserRouter as Router, Link, useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import useAPI from "utils/useAPI";
import CarouselService from "services/CarouselService";
import LocationService from "services/LocationService";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

const useStyles = makeStyles(styles);

export default function RegularForms() {
  const classes = useStyles();
  const history = useHistory();
  const api = useAPI();
  const [values, setValues] = React.useState({});
  const [locationList, setLocationList] = React.useState([]);
  const [userLocation, setUserLocation] = React.useState("");
  React.useEffect(() => {
    CarouselService.init(api);
    LocationService.init(api);
    fetchLocationList();
  }, [api]);

  const fetchLocationList = async () => {
    await LocationService.getLocations().then((res) => {
      setLocationList(
        res.map((item) => {
          return {
            id: item.id,
            name: item.name + " - " + item.zipcode,
            value: item.id,
          };
        })
      );
      if (res.length === 1) {
        setUserLocation(res[0].id);
      }
    });
  };

  const handleChange = (prop, event) => {
    setValues({ ...values, [prop]: event });
  };
  const handleLocationChange = (prop, event) => {
    setValues({ ...values, [prop]: event });
    setUserLocation(event);
  };
  const [ipAddress, setIpAddress] = React.useState("");
  // const handleValue = (prop) => (event) => {
  //   console.log("11111111");
  // };
  const handleSave = async () => {
    if (values["ip_address"] && values["ip_address"] !== "") {
      await CarouselService.storeCarousel(values);
      let path = `/admin/carousels`;
      history.push(path);
    } else {
      setIpAddress("error");
    }
  };
  const cancelRoute = () => {
    let path = `/admin/carousels`;
    history.push(path);
  };
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={6}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <CreateIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Add Carousel</h4>
          </CardHeader>
          <CardBody>
            <form>
              <FormControl
                variant="outlined"
                fullWidth
                margin="dense"
                className={classes.formControl}
              >
                <TextField
                  // error={errors.plate === "error"}
                  autoFocus
                  margin="dense"
                  id="ip_adress"
                  label="API Url"
                  error={ipAddress === "error"}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (event) => {
                      handleChange("ip_address", event.target.value);
                    },
                    // value: `${carousel.ip_address}`,
                  }}
                  variant="outlined"
                  type="text"
                />
                {/* <CustomInput
                  labelText="Name"
                  id="nick_name"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (event) => {
                      handleChange("nick_name", event.target.value);
                    },
                  }}
                /> */}
                <TextField
                  // error={errors.plate === "error"}
                  autoFocus
                  margin="dense"
                  id="nick_name"
                  label="Name"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (event) => {
                      handleChange("nick_name", event.target.value);
                    },
                    // value: `${carousel.nick_name}`,
                  }}
                  variant="outlined"
                  type="text"
                />

                <TextField
                  // error={errors.plate === "error"}
                  autoFocus
                  margin="dense"
                  id="description"
                  label="Description"
                  // error={ipAddress === "error"}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (event) => {
                      handleChange("description", event.target.value);
                    },
                    // value: carousel.description || "",
                  }}
                  variant="outlined"
                  type="text"
                />
                <FormControl
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  className={classes.formControl}
                >
                  <InputLabel>Location</InputLabel>
                  <Select
                    fullWidth
                    label="Location"
                    value={userLocation}
                    required
                    onChange={(e) => {
                      handleLocationChange("location_id", e.target.value);
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      Choose Location *
                    </MenuItem>

                    {locationList &&
                      locationList.map((location) => (
                        <MenuItem
                          value={`${location.id}`}
                          key={`${location.id}`}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                        >
                          {`${location.name}`}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </FormControl>
              {/* <Button color="white" onClick={handleValue}>
                Reset
              </Button> */}
              <Button color="success" onClick={handleSave}>
                Submit
              </Button>
              <Button color="danger" onClick={cancelRoute}>
                Cancel
              </Button>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
