import Axios from "axios";

const requests = [];
const { CancelToken } = Axios;
let api = Axios;

const getFeeStructure = async (
  pageSize,
  page,
  search,
  orderBy,
  orderDirection
) => {
  try {
    let cancel;
    const response = await api.get(
      "/admin/fees",
      { params: { pageSize, page, search, orderBy, orderDirection } },
      {
        cancelToken: new CancelToken((c) => {
          cancel = c;
          requests.push(cancel);
        }),
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const storeFee = async (data) => {
  try {
    let cancel;
    const response = await api.post("/admin/fees", data, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getFees = async (id) => {
  try {
    let cancel;
    const response = await api.get(`/admin/fees/${id}`, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const updateFee = async (data) => {
  try {
    let cancel;
    const response = await api.put("/admin/fees/", data, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const deleteFee = async (id) => {
  try {
    let cancel;
    const response = await api.delete(`/admin/fees/${id}`, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const init = async (axiosApi) => {
  api = axiosApi;
};

const FeeStructureService = {
  init,
  getFeeStructure,
  storeFee,
  getFees,
  updateFee,
  deleteFee,
};

export default FeeStructureService;
