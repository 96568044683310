/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import Radio from "@material-ui/core/Radio";
import { Autocomplete } from "@material-ui/lab";
import RadioGroup from "@material-ui/core/RadioGroup";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
// core components
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import { useHistory } from "react-router-dom";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import UserService from "services/UserService";
import VehicleService from "services/VehicleService";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import useAPI from "utils/useAPI";
const useStyles = makeStyles(styles);

function VehicleForm({ id = null }) {
  const [vehicleMakes, setVehicleMakes] = React.useState([""]);
  const [vehicleModels, setVehicleModels] = React.useState([""]);
  const [vehicleModel, setvehicleModel] = React.useState("");
  const [vehicleMake, setVehicleMake] = React.useState("");
  const [timerRef, settimerRef] = React.useState("");
  const [isEdit] = React.useState(id ? true : false);
  const [vehicleLabel] = React.useState(id ? "Update Vehicle" : "Add Vehicle");
  const color = [
    { name: "Gold" },
    { name: "Brown" },
    { name: "Green" },
    { name: "Yellow" },
    { name: "Orange" },
    { name: "Gray" },
    { name: "Silver" },
    { name: "Space Gray" },
    { name: "Blue" },
    { name: "Red" },
    { name: "Black" },
    { name: "White" },
  ];

  const [vehicle, setVehicle] = React.useState({
    make: "",
    model: "",
    plate: "",
    color: "Black",
    zip: "",
    user_id: "",
    is_default: 0,
  });
  const api = useAPI();
  const history = useHistory();
  const [users, setUsers] = React.useState([]);

  const [errors, setErrors] = React.useState({
    make: "",
    model: "",
    plate: "",
    type: "",
    color: "",
    zip: "",
    user_id: "",
  });

  const fetchVehicle = async () => {
    await VehicleService.getVehicle(id).then((res) => {
      setVehicle(res);
      if (id) {
        fetchVehicleMakes(res.make);
        setvehicleModel(res.model);
      }
    });
  };

  const fetchUser = async () => {
    await UserService.getUsers().then((res) => {
      setUsers(res.results);
    });
  };

  const fetchVehicleMakes = async (search) => {
    await VehicleService.getVehicleMakes(search).then((res) => {
      if (res) {
        setVehicleMakes(res);
      }
    });
  };
  const fetchVehicleModels = async (newValue) => {
    await VehicleService.getVehicleModel(newValue.id).then((res) => {
      if (res) {
        setVehicleModels(res);
      }
    });
  };

  React.useEffect(() => {
    UserService.init(api);
    VehicleService.init(api);
    fetchUser();
    if (id) fetchVehicle();
  }, [api]);

  React.useEffect(() => {
    setVehicleMake(vehicle.make);
  }, [vehicle]);

  const handleChange = (prop, val) => {
    console.log("Changed", prop, val);
    setVehicle({ ...vehicle, [prop]: val });
    if (verifyLength(val, 1)) {
      setErrors({ ...errors, [prop]: "success" });
    } else {
      setErrors({ ...errors, [prop]: "error" });
    }
  };

  // function that verifies if a string has a given length or not
  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  const registerClick = async () => {
    console.log("clickkk", vehicle);
    let error = 0;
    let newErrors = errors;
    ["make", "model", "plate", "color", "zip", "user_id"].forEach((field) => {
      if (vehicle[field] === "") {
        newErrors[field] = "error";
        error = 1;
      }
    });
    setErrors({ ...newErrors });

    if (error) return;

    if (id) await VehicleService.updateVehicle(vehicle);
    else await VehicleService.storeVehicle(vehicle);
    history.push("/admin/vehicles");
  };
  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <LocalShippingIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{vehicleLabel}</h4>
          </CardHeader>
          <CardBody>
            <form>
              {!isEdit && (
                <Autocomplete
                  id="combo-box-demo"
                  fullWidth
                  margin="dense"
                  options={vehicleMakes}
                  getOptionLabel={(option) => (option.name ? option.name : "")}
                  onInputChange={(event, args) => {
                    try {
                      if (timerRef) {
                        clearTimeout(timerRef);
                      }
                      if (args.length > 2) {
                        settimerRef(
                          setTimeout(() => {
                            fetchVehicleMakes(args);
                          }, 500)
                        );
                      } else {
                        setVehicleMakes([""]);
                      }
                    } catch (e) {
                      console.log(e);
                    }
                  }}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      // setValues({ ...values, make: newValue.name });
                      setErrors({ ...errors, make: "success" });
                      setVehicle({ ...vehicle, make: newValue.name });
                      fetchVehicleModels(newValue);
                      // setinputValue(false);
                    } else {
                      setVehicle({ ...vehicle, make: "", model: "" });
                      setVehicleModels([]);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="dense"
                      label="Make *"
                      variant="outlined"
                      error={errors.make === "error"}
                    />
                  )}
                />
              )}

              {isEdit && (
                <Autocomplete
                  id="combo-box-demo"
                  fullWidth
                  margin="dense"
                  options={vehicleMakes}
                  getOptionLabel={(option) => (option.name ? option.name : "")}
                  value={vehicleMake}
                  inputValue={vehicleMake}
                  onInputChange={(event, args, reason) => {
                    try {
                      if (reason === "input") {
                        setVehicleMake(args);
                        fetchVehicleMakes(args);
                      }
                      if (timerRef) {
                        clearTimeout(timerRef);
                      }
                      if (args.length > 2) {
                        settimerRef(
                          setTimeout(() => {
                            fetchVehicleMakes(args);
                          }, 500)
                        );
                      } else {
                        setVehicleMakes([]);
                      }
                    } catch (e) {
                      console.log(e);
                    }
                  }}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setVehicleMake(newValue.name);
                      setvehicleModel("");
                      setErrors({ ...errors, make: "success" });
                      setVehicle({ ...vehicle, make: newValue.name });
                      fetchVehicleModels(newValue);
                    } else {
                      setVehicle({ ...vehicle, make: "", model: "" });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="dense"
                      label="Make *"
                      variant="outlined"
                      error={errors.make === "error"}
                    />
                  )}
                />
              )}

              <Autocomplete
                id="combo-box-demo"
                fullWidth
                options={vehicleModels}
                getOptionLabel={(option) => (option.name ? option.name : "")}
                value={vehicleModel}
                inputValue={vehicleModel}
                onChange={(event, newValue) => {
                  setErrors({ ...errors, model: "success" });
                  if (newValue) {
                    setvehicleModel(newValue.name);
                    setVehicle({ ...vehicle, model: newValue.name });
                  } else {
                    setVehicle({ ...vehicle, model: "" });
                  }
                }}
                onInputChange={(event, args, reason) => {
                  if (reason === "input") setvehicleModel(args);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="dense"
                    error={errors.model === "error"}
                    label="Model"
                    variant="outlined"
                  />
                )}
              />

              <TextField
                error={errors.plate === "error"}
                margin="dense"
                id="registerplate"
                label="Plate *"
                variant="outlined"
                type="text"
                fullWidth
                value={vehicle.plate}
                onChange={(event) => {
                  handleChange("plate", event.target.value);
                }}
              />

              <FormControl
                fullWidth
                variant="outlined"
                margin="dense"
                className={classes.formControl}
              >
                <InputLabel>Choose Color *</InputLabel>
                <Select
                  value={vehicle.color}
                  onChange={(e) => {
                    handleChange("color", e.target.value);
                  }}
                  error={errors.color === "error"}
                  label="Choose Color *"
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Choose Color *
                  </MenuItem>

                  {color.map((color) => (
                    <MenuItem
                      value={`${color.name}`}
                      key={`${color.name}`}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                    >
                      {`${color.name}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                error={errors.zip === "error"}
                margin="dense"
                id="registerZip"
                label="Zip *"
                variant="outlined"
                type="text"
                fullWidth
                value={vehicle.zip}
                onChange={(event) => {
                  handleChange("zip", event.target.value);
                }}
              />

              <FormControl
                fullWidth
                variant="outlined"
                margin="dense"
                className={classes.formControl}
              >
                <InputLabel>Choose User *</InputLabel>
                <Select
                  value={vehicle.user_id}
                  onChange={(e) => {
                    handleChange("user_id", e.target.value);
                  }}
                  error={errors.user_id === "error"}
                  inputProps={{
                    name: "simpleSelect",
                    id: "simple-select",
                  }}
                  label="Choose User *"
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Choose User *
                  </MenuItem>

                  {users.map((user, index) => (
                    <MenuItem
                      value={`${user.id}`}
                      key={`${user.id}`}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                    >
                      {`${user.name}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <div className={classes.formCategory}>
                <small>*</small> Required fields
              </div>

              <Button
                color="rose"
                onClick={registerClick}
                className={classes.registerButton}
              >
                {vehicleLabel}
              </Button>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

VehicleForm.propTypes = {
  id: PropTypes.string,
};

VehicleForm.defaultProps = {
  id: null,
};

export default VehicleForm;
