import Axios from "axios";

const requests = [];
const { CancelToken } = Axios;
let api = Axios;

const getCarousels = async (pageSize, page, q) => {
  try {
    let cancel;
    const response = await api.get(
      "/admin/carousels",
      { params: { pageSize, page, q } },
      {
        cancelToken: new CancelToken((c) => {
          cancel = c;
          requests.push(cancel);
        }),
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getCarousel = async (id) => {
  try {
    let cancel;
    const response = await api.get(`/admin/carousels/${id}`, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getCarouselByToken = async (token) => {
  try {
    let cancel;
    const response = await api.get(`/admin/carousel-by-token/${token}`, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data[0];
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const deleteCarousel = async (id) => {
  try {
    let cancel;
    const response = await api.delete(`/admin/carousels/${id}`, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const storeCarousel = async (data) => {
  try {
    let cancel;
    const response = await api.post("/admin/carousels", data, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const updateCarousel = async (data) => {
  try {
    let cancel;
    const response = await api.post(`/admin/carousels/${data.id}`, data, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getCarouselStatus = async (id) => {
  try {
    let cancel;
    const response = await api.get(`/admin/carousels/${id}/status`, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    console.log("11111111", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getProxyCarousel = async (id) => {
  try {
    let cancel;
    const response = await api.get(`/admin/carousels/${id}/location`, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
const init = async (axiosApi) => {
  api = axiosApi;
};

const VehicleService = {
  init,
  getCarousel,
  getCarouselByToken,
  getCarousels,
  deleteCarousel,
  storeCarousel,
  updateCarousel,
  getCarouselStatus,
  getProxyCarousel,
};

export default VehicleService;
