/* eslint-disable react/display-name */

import React from "react";

import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Container,
  Grid,
  Avatar,
  Typography,
} from "@material-ui/core";
import useAPI from "utils/useAPI";
import UserService from "services/UserService";
import InputMask from "react-input-mask";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import GroupIcon from "@material-ui/icons/Group";
import CardBody from "components/Card/CardBody";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

export const ProfileComponent = () => {
  const { user } = useAuth0();
  const classes = useStyles();

  const [saving, setSaving] = React.useState(false);
  const [systemUser, setSystemUser] = React.useState(null);

  const api = useAPI();

  const [values, setValues] = React.useState({});

  const fetchUser = async () => {
    api.get("user").then((res) => {
      let sysUser = res.data;
      console.log(sysUser);
      Object.keys(sysUser).forEach((key) => {
        sysUser[key] = sysUser[key] ? sysUser[key] : "";
      });
      console.log(sysUser);
      setValues({ ...sysUser });
      setSystemUser(sysUser);
    });
  };

  React.useEffect(() => {
    UserService.init(api);
    fetchUser();
  }, [api]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleSave = async () => {
    setSaving(true);
    await UserService.updateUser(values);
    fetchUser();
    setSaving(false);
  };

  if (!systemUser) {
    return (
      <Container className="mb-5">
        <Grid container wrap="nowrap" spacing={2}>
          <Grid item>
            <Avatar
              alt={user.name}
              src={user.picture}
              className={classes.large}
            />
          </Grid>
          <Grid item xs zeroMinWidth>
            <Typography noWrap>{user.name}</Typography>
            <Typography variant="body2" noWrap>
              {user.email}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    );
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <GroupIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Users</h4>
          </CardHeader>
          <CardBody>
            <Container className="mb-5">
              <Grid container wrap="nowrap" spacing={2}>
                <Grid item>
                  <Avatar
                    alt={user.name}
                    src={user.picture}
                    className={classes.large}
                  />
                </Grid>
                <Grid item xs zeroMinWidth>
                  <Typography noWrap>{user.name}</Typography>
                  <Typography variant="body2" noWrap>
                    {user.email}
                  </Typography>
                </Grid>
              </Grid>
              <Grid>
                <Box pb={5}>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Name"
                    variant="outlined"
                    value={values.name}
                    onChange={handleChange("name")}
                    type="text"
                    fullWidth
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    id="address"
                    label="Address"
                    value={values.address}
                    variant="outlined"
                    onChange={handleChange("address")}
                    type="text"
                    fullWidth
                    multiline
                    rows={3}
                  />

                  <TextField
                    autoFocus
                    margin="dense"
                    id="city"
                    label="City"
                    value={values.city}
                    variant="outlined"
                    onChange={handleChange("city")}
                    type="text"
                    fullWidth
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    id="state"
                    label="State"
                    variant="outlined"
                    value={values.state}
                    onChange={handleChange("state")}
                    type="text"
                    fullWidth
                  />

                  {/* <TextField
              autoFocus
              margin="dense"
              id="zipcode"
              label="Zipcode"
              variant="outlined"
              value={values.zipcode}
              onChange={handleChange("zipcode")}
              type="number"
              fullWidth
            /> */}

                  <InputMask
                    mask="99999"
                    value={values.zipcode}
                    disabled={false}
                    onChange={handleChange("zipcode")}
                    maskChar=" "
                  >
                    {() => (
                      <TextField
                        autoFocus
                        margin="dense"
                        id="zipcode"
                        label="Zip Code"
                        variant="outlined"
                        value={values.zipcode}
                        type="text"
                        fullWidth
                      />
                    )}
                  </InputMask>

                  <TextField
                    autoFocus
                    margin="dense"
                    id="email"
                    label="Email"
                    variant="outlined"
                    value={values.email}
                    onChange={handleChange("email")}
                    type="email"
                    disabled
                    fullWidth
                  />

                  {/* <TextField
              autoFocus
              margin="dense"
              id="phone"
              label="Phone"
              variant="outlined"
              value={values.phone}
              onChange={handleChange("phone")}
              type="text"
              fullWidth
            /> */}

                  <InputMask
                    mask="+1 (999) 999 9999"
                    value={values.phone}
                    disabled={false}
                    onChange={handleChange("phone")}
                    maskChar=" "
                  >
                    {() => (
                      <TextField
                        autoFocus
                        margin="dense"
                        id="phone"
                        label="Phone"
                        variant="outlined"
                        value={values.phone}
                        type="text"
                        fullWidth
                      />
                    )}
                  </InputMask>

                  <TextField
                    autoFocus
                    margin="dense"
                    id="licenseNumber"
                    label="License #"
                    variant="outlined"
                    value={values.licenseNumber}
                    onChange={handleChange("licenseNumber")}
                    type="text"
                    fullWidth
                  />

                  <TextField
                    autoFocus
                    margin="dense"
                    id="licenseState"
                    label="License State"
                    variant="outlined"
                    value={values.licenseState}
                    onChange={handleChange("licenseState")}
                    type="text"
                    fullWidth
                  />
                  <Button
                    onClick={handleSave}
                    variant="contained"
                    color="primary"
                    fullWidth
                    className="mt-3"
                  >
                    Save
                  </Button>
                  {saving && <CircularProgress size={24} />}
                </Box>
              </Grid>
              {/* <Row>
          <Highlight>{JSON.stringify(user, null, 2)}</Highlight>
        </Row> */}
            </Container>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default ProfileComponent;
