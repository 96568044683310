import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import CreateIcon from "@material-ui/icons/Create";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import InputMask from "react-input-mask";
import { useHistory } from "react-router-dom";
import useAPI from "utils/useAPI";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import TicketService from "services/TicketService";
import ConfigurationService from "services/ConfigurationService";
import {
  FormControl,
  Switch,
  FormControlLabel,
  Checkbox,
  Grid,
} from "@material-ui/core";

const useStyles = makeStyles(styles);

const vehicleSizeList = require("../../assets/json/VehicleTypes");
export default function RegularForms() {
  const classes = useStyles();
  const history = useHistory();
  const api = useAPI();
  const color = require("../../assets/json/Colors");

  const [configurationList, setConfigurationList] = React.useState([]);
  const [ticket, setTicket] = React.useState({
    phone: "",
    name: "",
    vehicleType: "",
    color: "",
    plate: "",
    charging: false,
    total_amount: null,
    fee_override: false,
    configuration: [],
    notes: "",
  });
  const [errors, setErrors] = React.useState({
    phone: "",
    name: "",
    vehicleType: "",
    color: "",
    plate: "",
    total_amount: "",
  });

  const fetchConfiguration = () => {
    ConfigurationService.getConfigurations()
      .then((res) => {
        setConfigurationList(res.results);
      })
      .catch();
  };

  React.useEffect(() => {
    TicketService.init(api);
    ConfigurationService.init(api);
    fetchConfiguration();
  }, [api]);

  const handleChange = (prop) => (event) => {
    console.log("Changed", prop);
    setTicket({ ...ticket, [prop]: event.target.value });
    if (verifyLength(event.target.value, 1)) {
      setErrors({ ...errors, [prop]: "success" });
    } else {
      setErrors({ ...errors, [prop]: "error" });
    }
  };
  const handleCheckbox = (prop) => (event) => {
    if (event.target.checked) {
      // charging bicycle
      ticket.total_amount = "12";
    } else {
      // parking bicycle
      ticket.total_amount = "";
    }

    setTicket({ ...ticket, [prop]: event.target.checked });
  };

  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  const handleSave = async () => {
    console.log(ticket);
    let error = 0;
    let newErrors = errors;
    console.log("!11111");
    ["phone", "vehicleType", "color"].forEach((field) => {
      if (ticket[field] === "") {
        newErrors[field] = "error";
        error = 1;
      }
    });
    console.log("!222222");
    if (ticket["vehicleType"] !== "BICYCLE") {
      if (ticket["plate"] === "") {
        newErrors["plate"] = "error";
        error = 1;
      }
    }
    console.log("!33333", ticket, newErrors);
    setErrors({ ...newErrors });
    if (error) return;
    console.log("!444444");
    if (!ticket.fee_override) {
      ticket.total_amount = null;
    }
    let path = `/admin/ticket`;
    await TicketService.storeTicket(ticket).then(() => {
      history.push(path);
    });
  };

  const cancelRoute = () => {
    window.history.back();
    // let path = `/admin/carousels`;
    // history.push(path);
  };
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={6}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <CreateIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Add Ticket</h4>
          </CardHeader>
          <CardBody>
            <form>
              <InputMask
                mask="+1 (999) 999 9999"
                // value={values.phone}
                disabled={false}
                onChange={handleChange("phone")}
                maskChar=" "
              >
                {() => (
                  <TextField
                    error={errors.phone === "error"}
                    margin="dense"
                    id="phone"
                    label="Phone *"
                    variant="outlined"
                    // value={ticket.phone}
                    type="text"
                    fullWidth
                  />
                )}
              </InputMask>

              <TextField
                error={errors.name === "error"}
                margin="dense"
                id="name"
                label="Name"
                variant="outlined"
                type="text"
                fullWidth
                onChange={handleChange("name")}
              />

              <FormControl
                variant="outlined"
                fullWidth
                margin="dense"
                className={classes.formControl}
              >
                <InputLabel id="vehicle_type">Vehicle Type *</InputLabel>
                <Select
                  error={errors.vehicleType === "error"}
                  labelId="vehicle_type"
                  id="vehicle_type"
                  fullWidth
                  value={ticket.vehicleType}
                  onChange={handleChange("vehicleType")}
                  label="Vehicle Type *"
                >
                  <MenuItem
                    disabled
                    value=""
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Choose Vehicle Type
                  </MenuItem>

                  {vehicleSizeList &&
                    vehicleSizeList.map((item) => (
                      <MenuItem
                        value={`${item.name}`}
                        key={`${item.id}`}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                      >
                        {`${item.name}`}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <TextField
                error={errors.plate === "error"}
                margin="dense"
                id="plate"
                label="Plate *"
                variant="outlined"
                type="text"
                fullWidth
                onChange={handleChange("plate")}
              />

              <FormControl
                variant="outlined"
                fullWidth
                margin="dense"
                className={classes.formControl}
              >
                <InputLabel id="color">Color *</InputLabel>
                <Select
                  labelId="color"
                  id="color"
                  fullWidth
                  value={ticket.color}
                  onChange={handleChange("color")}
                  label="Color *"
                  error={errors.color === "error"}
                >
                  <MenuItem
                    disabled
                    value=""
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Choose Color *
                  </MenuItem>

                  {color.map((color) => (
                    <MenuItem
                      value={`${color.name}`}
                      key={`${color.name}`}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                    >
                      {`${color.name}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                variant="outlined"
                fullWidth
                margin="dense"
                className={classes.formControl}
              >
                <InputLabel id="color">Additional Fees</InputLabel>
                <Select
                  multiple
                  labelId="configuration"
                  id="configuration"
                  fullWidth
                  value={ticket.configuration}
                  onChange={(e) => {
                    setTicket({ ...ticket, configuration: e.target.value });
                  }}
                  label="Additional Fees"
                >
                  <MenuItem
                    multiple={true}
                    disabled
                    value=""
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Choose Additional Fees
                  </MenuItem>

                  {configurationList.map((item) => (
                    <MenuItem
                      value={`${item.id}`}
                      key={`${item.name}`}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                    >
                      {`${item.name} - $${item.amount}`}
                    </MenuItem>
                  ))}
                </Select>
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={ticket.fee_override}
                        onChange={handleCheckbox("fee_override")}
                      />
                    }
                    label="Fee override"
                  />
                  {ticket.fee_override && (
                    <TextField
                      error={errors.total_amount === "error"}
                      margin="dense"
                      id="total_amount"
                      label="Override Amount "
                      variant="outlined"
                      type="text"
                      fullWidth
                      value={ticket.total_amount || ""}
                      onChange={handleChange("total_amount")}
                    />
                  )}
                </>
              </FormControl>
              <GridItem xs={12} sm={12} md={6}>
                <FormControlLabel
                  control={<> </>}
                  label={"Park"}
                  labelPlacement="begin"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={ticket.charging}
                      onChange={handleCheckbox("charging")}
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  }
                  label={"Charge"}
                  labelPlacement="end"
                />
              </GridItem>

              <TextField
                id="outlined-multiline-static"
                label="Notes"
                multiline
                rows={2}
                defaultValue=""
                variant="outlined"
                fullWidth
                onChange={handleChange("notes")}
              />

              <Button color="success" onClick={handleSave}>
                Submit
              </Button>
              <Button color="danger" onClick={cancelRoute}>
                Cancel
              </Button>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
