import React from "react";

// core components
import Wizard from "components/Wizard/Wizard.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Account from "./WizardSteps/Account.js";
import Organization from "./WizardSteps/Organization.js";
import Sites from "./WizardSteps/Sites.js";

export default function WizardView() {
  return (
    <GridContainer justify="center">
      <GridItem xs={12} sm={8}>
        <Wizard
          validate
          steps={[
            { stepName: "Account", stepComponent: Account, stepId: "account" },
            {
              stepName: "Organization",
              stepComponent: Organization,
              stepId: "organization",
            },
            { stepName: "Sites", stepComponent: Sites, stepId: "sites" },
          ]}
          title="Stak Mobility"
          subtitle="Sign up to create a Stak Motor Club Account."
          finishButtonClick={(e) => {
            console.log("dataaaaaaaaaaofstateeeeeeeeee", e);
          }}
        />
      </GridItem>
    </GridContainer>
  );
}
