import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useAuth0 } from "@auth0/auth0-react";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-pro-react/views/errorPageStyles.js";

const useStyles = makeStyles(styles);

export default function ErrorPage() {
  const classes = useStyles();
  const { logout } = useAuth0();

  const handleLogout = () => {
    logout({
      returnTo: window.location.origin,
    });
  };
  return (
    <div className={classes.contentCenter}>
      <GridContainer>
        <GridItem md={12}>
          <h1 className={classes.title}>oops</h1>
          <h2 className={classes.subTitle}>
            Seems you do not have permission :(
          </h2>

          <Button color="warning" onClick={handleLogout}>
            Login Again
          </Button>
        </GridItem>
      </GridContainer>
    </div>
  );
}
