import React from "react";
import MUIDataTable from "mui-datatables";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import { makeStyles } from "@material-ui/core/styles";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.js";
import MenuItem from "@material-ui/core/MenuItem";
// apis
import SubscriptionService from "services/SubscriptionService";
import useAPI from "utils/useAPI";
import { useHistory } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Close from "@material-ui/icons/Close";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import Select from "@material-ui/core/Select";
import { Tooltip, Typography } from "@material-ui/core";

import AddIcon from "@material-ui/icons/Add";
import ListAltIcon from "@material-ui/icons/ListAlt";
import EditIcon from "@material-ui/icons/Edit";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles(modalStyle);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const vehicleStatusList = [
  { id: 0, name: "unparked" },
  { id: 1, name: "parked" },
  { id: 2, name: "retrieved" },
];

export default function Tickets() {
  const api = useAPI();
  const history = useHistory();
  let path = "/subscription/edit/";
  const [modal, setModal] = React.useState(false);
  const [status, setStatus] = React.useState();
  const [ticketId, setTicketId] = React.useState(0);
  const classes = useStyles();
  const [data, setData] = React.useState(null);

  const handleChange = (event) => {
    console.log(event);
  };

  const changeStatus = async (data) => {
    await SubscriptionService.changeStatus(data);
  };

  const fetchSubscriptions = () => {
    SubscriptionService.getPlans()
      .then((res) => {
        const d = transform(res.results);
        setData(d);
      })
      .catch();
  };
  React.useEffect(() => {
    SubscriptionService.init(api);
    fetchSubscriptions();
  }, [api]);

  const filterString = (value) => {
    return value ? value : "-";
  };

  const columns = [
    {
      label: "Plan Name",
      name: "plan_name",
    },
    {
      label: "Amount($)",
      name: "amount",
    },
    {
      label: "Duration  in Month(s)",
      name: "duration",
    },
    {
      label: "Status",
      name: "status",
    },
    {
      label: "Location",
      name: "location_id",
    },
    {
      label: "Actions",
      name: "actions",
    },
  ];
  const options = {
    filter: false, // Hide filters
    print: false, // Hide print
    download: false, // Hide download
    selectableRowsHeader: false, // Hide checkbox in header
    selectableRowsHideCheckboxes: true, // Hide checkbox for every row
    customToolbar: () => {
      return (
        <Tooltip title="Add Subscription" aria-label="">
          <IconButton
            onClick={() => {
              history.push("/subscription/add");
            }}
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      );
    },
  };

  function transform(session) {
    let transformed_plan = session;
    transformed_plan = transformed_plan.map((plan) => {
      return {
        id: plan.id,
        plan_name: filterString(plan.plan_name),
        amount: plan.amount,
        duration: plan.duration,
        status: (
          <>
            {" "}
            <div display="flex">
              <FormControlLabel
                control={
                  <Switch
                    checked={plan.status === "Active" ? true : false}
                    onChange={(e) => {
                      console.log("111", e.target.checked);
                      changeStatus({
                        id: plan.id,
                        status: e.target.checked ? "Active" : "Inactive",
                      }).then(() => {
                        fetchSubscriptions();
                      });
                    }}
                    name="status"
                  />
                }
                label={plan.status ? plan.status : "NA"}
              />
            </div>
          </>
        ),
        actions: (
          <>
            <div display="flex">
              <Tooltip title="Update Subscription Plan" aria-label="">
                <IconButton
                  onClick={async () => {
                    history.push(path + plan.id);
                  }}
                >
                  <EditIcon color="primary" />
                </IconButton>
              </Tooltip>
            </div>
          </>
        ),
        location_id: plan.location ? plan.location.name : "NA",
      };
    });
    return transformed_plan;
  }
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          {data && (
            <MUIDataTable
              title={
                <div>
                  <CardIcon color="rose">
                    <ListAltIcon style={{ color: "white" }} />{" "}
                  </CardIcon>
                  <Typography variant="h6">SUBSCRIPTIONS</Typography>
                </div>
              }
              data={data}
              columns={columns}
              options={options}
            />
          )}

          <Dialog
            classes={{
              root: classes.center,
              paper: classes.modal,
            }}
            open={modal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => {
              setModal(false);
              setStatus("");
              setTicketId(0);
            }}
            aria-labelledby="modal-slide-title"
            aria-describedby="modal-slide-description"
          >
            <DialogTitle
              id="classic-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <IconButton
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={() => {
                  setModal(false);
                  setStatus("");
                  setTicketId(0);
                }}
              >
                <Close className={classes.modalClose} />
              </IconButton>
              <h4 className={classes.modalTitle}>Change Vehicle Status</h4>
            </DialogTitle>
            <DialogContent
              id="modal-slide-description"
              className={classes.modalBody}
            >
              {/* <h5>Are you sure you want to do this?</h5> */}
              <Select
                fullWidth
                id="vehicle_status"
                value={status}
                required
                onChange={(event) => {
                  handleChange(event.target.value);
                }}
                // style={("paddingTop" = "10px")}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Vehicle Status *
                </MenuItem>

                {vehicleStatusList &&
                  vehicleStatusList.map((item) => (
                    <MenuItem
                      value={`${item.name}`}
                      key={`${item.id}`}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                    >
                      {capitalizeFirstLetter(`${item.name}`)}
                    </MenuItem>
                  ))}
              </Select>
            </DialogContent>
            <DialogActions
              className={classes.modalFooter + " " + classes.modalFooterCenter}
            >
              <Button
                onClick={() => {
                  setModal(false);
                  setStatus("");
                  setTicketId(0);
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  setModal(false);
                  console.log(status);
                  changeStatus({
                    id: ticketId,
                    vehicle_status: status,
                  }).then(() => {
                    console.log("Completed");
                    fetchSubscriptions();
                  });
                  setStatus("");
                  setTicketId(0);
                }}
                color="primary"
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
