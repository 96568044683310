import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import useAPI from "utils/useAPI";
import ParkingSessionService from "services/ParkingSessionService";
import buttons from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import FormControl from "@material-ui/core/FormControl";

const styles = {
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    buttons,
  },
  textCenter: {
    textAlign: "center",
  },
  textMuted: {
    color: "#6c757d",
  },
};
const useStyles = makeStyles(styles);

export default function RegularForms() {
  const classes = useStyles();
  const history = useHistory();
  const api = useAPI();
  let params = useParams();
  const [session, setSession] = React.useState({});
  const [discountList, setDiscountList] = React.useState([]);
  const [discount, setDiscount] = React.useState("");
  const fetchDiscountList = async () => {
    await ParkingSessionService.discountList().then((res) => {
      console.log("1111", res);
      setDiscountList(
        res.results.map((item) => {
          return {
            id: item.id,
            name: `${item.value}${item.type === "Amount" ? "$" : "%"}`,
            value: item.value,
          };
        })
      );
    });
  };
  const fetchSession = async () => {
    await ParkingSessionService.getSessionDetail(params.id).then((res) => {
      setSession(res);
      setDiscount(res.discount_id);
    });
  };

  React.useEffect(() => {
    ParkingSessionService.init(api);
    fetchSession();
    fetchDiscountList();
  }, [api]);

  const handleSave = async () => {
    console.log("22", discount);
    if (discount !== null) {
      await ParkingSessionService.applyDiscount({
        parking_session_id: session.id,
        discount_id: discount,
      });
      let path = `/parking-sessions`;
      history.push(path);
    }
  };
  const cancelRoute = () => {
    let path = `/parking-sessions`;
    history.push(path);
  };
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={6}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <LocalAtmIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Apply Discount</h4>
          </CardHeader>
          <CardBody className={classes.textCenter}>
            <b>
              {" "}
              Vehicle PIN: {session.user_vehicle_pin}
              <br />
              Vehicle Name: {session.vehicle_name}
              <br />
              Status: {String(session.status).toUpperCase()}
              {/* <Chip
                label={String(session.status).toUpperCase()}
                variant="outlined"
                color={session.status === "retrieved" ? "primary" : "secondary"}
              />{" "} */}
              <br />
              Parked Time:{" "}
              {moment(session.start_time).format("MM/DD/YYYY hh:mm A")}
            </b>
            <form style={{ display: "block", marginTop: "25px" }}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  htmlFor="simple-select"
                  className={classes.selectLabel}
                >
                  Choose discount
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={discount}
                  onChange={(e) => {
                    setDiscount(e.target.value);
                  }}
                  inputProps={{
                    name: "simpleSelect",
                    id: "simple-select",
                  }}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Choose discount *
                  </MenuItem>

                  {discountList.map((discountListItem) => (
                    <MenuItem
                      value={`${discountListItem.id}`}
                      key={`${discountListItem.id}`}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                    >
                      {`${discountListItem.name}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Button color="success" onClick={handleSave}>
                Apply Discount
              </Button>
              <Button color="danger" onClick={cancelRoute}>
                Cancel
              </Button>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
