import Axios from "axios";

const requests = [];
const { CancelToken } = Axios;
let api = Axios;

const getSubscriptionBookings = async (
  pageSize,
  page,
  search,
  orderBy,
  orderDirection
) => {
  try {
    let cancel;
    const response = await api.get(
      "/admin/subscription-bookings",
      { params: { pageSize, page, search, orderBy, orderDirection } },
      {
        cancelToken: new CancelToken((c) => {
          cancel = c;
          requests.push(cancel);
        }),
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getBookingHistory = async (
  id,
  pageSize,
  page,
  search,
  orderBy,
  orderDirection
) => {
  try {
    let cancel;
    const response = await api.get(
      `/admin/subscription-bookings/${id}/history`,
      { params: { pageSize, page, search, orderBy, orderDirection } },
      {
        cancelToken: new CancelToken((c) => {
          cancel = c;
          requests.push(cancel);
        }),
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getSubscriptionBookingDetail = async (id) => {
  try {
    let cancel;
    const response = await api.get(`/admin/subscription-bookings/${id}`, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const updateCurrentSubscriptionBooking = async (data) => {
  try {
    let cancel;
    const response = await api.put(
      `/admin/subscription-bookings/${data.id}`,
      data,
      {
        cancelToken: new CancelToken((c) => {
          cancel = c;
          requests.push(cancel);
        }),
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const sendSMS = async (id, message) => {
  try {
    let cancel;
    const response = await api.post(
      `/admin/subscription-bookings/${id}/send`,
      { message: message },
      {
        cancelToken: new CancelToken((c) => {
          cancel = c;
          requests.push(cancel);
        }),
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const makeRefund = async (amount, message) => {
  try {
    let cancel;
    const response = await api.post(
      `/admin/stripe/refund`,
      { amount, message },
      {
        cancelToken: new CancelToken((c) => {
          cancel = c;
          requests.push(cancel);
        }),
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getInvoiceLink = async (stripeSubscriptionId) => {
  try {
    let cancel;
    const response = await api.get(
      `/admin/subscription-invoice-link/${stripeSubscriptionId}`,
      {
        cancelToken: new CancelToken((c) => {
          cancel = c;
          requests.push(cancel);
        }),
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
const init = async (axiosApi) => {
  api = axiosApi;
};

const VehicleService = {
  init,
  getSubscriptionBookings,
  getSubscriptionBookingDetail,
  getBookingHistory,
  updateCurrentSubscriptionBooking,
  sendSMS,
  getInvoiceLink,
  makeRefund,
};

export default VehicleService;
