import React from "react";
import MUIDataTable from "mui-datatables";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// material-ui icons
import GroupIcon from "@material-ui/icons/Group";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import UserService from "services/UserService";
import useAPI from "utils/useAPI";
import { Chip, Tooltip, IconButton, Typography } from "@material-ui/core";
import MaterialTable, { MTableToolbar } from "material-table";
import history from "utils/history";
import EditIcon from "@material-ui/icons/Edit";
const useStyles = makeStyles((theme) => ({
  ...styles,
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export default function Users() {
  const api = useAPI();
  const classes = useStyles();
  let path = "/users/edit/";
  const [data, setData] = React.useState(null);

  function transformUsers(users) {
    let transformed_users = users;
    transformed_users = transformed_users.map((user) => {
      var vehicleList = "";
      var vehicleMakes = user.vehicles.map((v) => v.make);
      var uniqueVehicleMakes = vehicleMakes.filter(
        (v, i, a) => a.indexOf(v) === i
      );
      vehicleList = (
        <>
          {uniqueVehicleMakes.map((v) => (
            <Chip
              variant="outlined"
              className={classes.chip}
              key={v}
              label={v}
              m={2}
            />
          ))}
        </>
      );
      return {
        id: user.id,
        name: user.nickname,
        email: user.email,
        role: uppercaseFirstLetter(user.role),
        status: uppercaseFirstLetter(user.status),
        picture: user.picture,
        vehicle: vehicleList,
        is_agree: user.is_agree ? "Not Accepted" : "Accepted",
        actions: (
          <Tooltip title="Retrieve" aria-label="">
            <IconButton
              onClick={() => {
                history.push(path + user.id);
              }}
            >
              <EditIcon color="primary" />
            </IconButton>
          </Tooltip>
        ),
      };
    });
    return transformed_users;
  }

  const fetchUsers = () => {
    UserService.getUsers()
      .then((res) => {
        let d = transformUsers(res.results);
        setData(d);
      })
      .catch();
  };
  React.useEffect(() => {
    UserService.init(api);
    fetchUsers();
  }, [api]);

  function uppercaseFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const columns = [
    {
      label: "Id",
      name: "id",
      options: {
        filter: false,
      },
    },
    {
      label: "Name",
      name: "name",
      options: {
        filter: false,
      },
    },
    {
      label: "Email",
      name: "email",
      options: {
        filter: false,
      },
    },
    {
      label: "Status",
      name: "status",
    },
    {
      label: "Vehicle",
      name: "vehicle",
      options: {
        filter: false,
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = obj1.data.props.children;
            let val2 = obj2.data.props.children;
            if (val1.length === val2.length && val1.length !== 0) {
              return (
                val1[0].key.localeCompare(val2[0].key) *
                (order === "asc" ? 1 : -1)
              );
            }
            return (
              (val1.length < val2.length ? -1 : 1) * (order === "asc" ? 1 : -1)
            );
          };
        },
      },
    },
    {
      label: "Role",
      name: "role",
    },
    {
      label: "Terms",
      name: "is_agree",
    },
    {
      label: "Actions",
      name: "actions",
      options: {
        filter: false,
      },
    },
  ];
  const options = {
    filter: true, // Hide filters
    confirmFilters: true,
    print: false, // Hide print
    download: false, // Hide download
    selectableRowsHeader: false, // Hide checkbox in header
    selectableRowsHideCheckboxes: true, // Hide checkbox for every row
  };

  return (
    <GridContainer>
      <div className={classes.cardContentLeft}></div>
      <GridItem xs={12}>
        <Card>
          {data && (
            <MUIDataTable
              title={
                <div>
                  <CardIcon color="rose">
                    <GroupIcon style={{ color: "white" }} />{" "}
                  </CardIcon>
                  <Typography variant="h6">Users</Typography>
                </div>
              }
              data={data}
              columns={columns}
              options={options}
            />
          )}

          {/* <MaterialTable
            title="Users"
            tableRef={tableRef}
            columns={[
              { title: "ID", field: "id", filtering: false },
              { title: "Name", field: "name", filtering: false },
              { title: "Email", field: "email", filtering: false },
              {
                title: "Status",
                field: "status",
                lookup: {
                  active: "Active",
                  waiting: "Waiting",
                  suspend: "Suspend",
                },
              },
              {
                title: "Vehicles",
                field: "vehicle",
                filtering: false,
                sorting: false,
              },
              {
                title: "Role",
                field: "role",
                lookup: {
                  admin: "Admin",
                  customer: "Customer",
                  "carousel-admin": "Park Admin",
                },
              },
              {
                title: "Terms",
                field: "is_agree",
                lookup: { 0: "Not Accepted", 1: "Accepted" },
              },
            ]}
            data={handleData}
            actions={[
              (rowData) => ({
                icon: "edit",
                iconProps: { fontSize: "small" },
                tooltip: "Update User",
                onClick: () => {
                  history.push(path + rowData.id);
                },
              }),
            ]}
            components={{
              Toolbar: (props) => (
                <div>
                  <CardHeader color="primary" icon>
                    <CardIcon color="rose">
                      <GroupIcon />
                    </CardIcon>
                  </CardHeader>
                  <MTableToolbar {...props} />
                </div>
              ),
            }}
            options={{
              actionsColumnIndex: -1,
              debounceInterval: 500,
              filtering: true,
              sorting: true,
              pageSize: 10,
            }}
          /> */}
        </Card>
      </GridItem>
    </GridContainer>
  );
}
