import React from "react";
import MUIDataTable from "mui-datatables";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardIcon from "components/Card/CardIcon.js";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import FeeStructureService from "services/FeeStructureService";
import useAPI from "utils/useAPI";
import buttons from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";
import { useHistory } from "react-router-dom";
import tableStyles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import { Typography, Tooltip, IconButton } from "@material-ui/core";
import CraeteIcon from "@material-ui/icons/Create";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
    buttons,
  },
  ...tableStyles,
};

const useStyles = makeStyles(styles);

export default function ReactTables() {
  const api = useAPI();
  const history = useHistory();
  let path = "/fee-structure/edit/";
  const [data, setData] = React.useState(null);

  const fetchFeeStructure = () => {
    FeeStructureService.getFeeStructure()
      .then((res) => {
        let d = transform(res.results);
        setData(d);
      })
      .catch();
  };

  React.useEffect(() => {
    FeeStructureService.init(api);
    fetchFeeStructure();
  }, [api]);

  function transform(Fee) {
    let transformed_fees = Fee;
    transformed_fees = transformed_fees.map((ses) => {
      return {
        id: ses.id,
        amount: ses.amount,
        duration: ses.duration + " " + ses.type,
        actions: (
          <>
            <div display="flex">
              <Tooltip title="Update FeeStructure" aria-label="">
                <IconButton
                  onClick={() => {
                    history.push(path + ses.id);
                  }}
                >
                  <CraeteIcon style={{ color: "#0C3C78" }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete" aria-label="">
                <IconButton
                  onClick={async () => {
                    await FeeStructureService.deleteFee(ses.id);
                  }}
                >
                  <DeleteIcon style={{ color: "red" }} />
                </IconButton>
              </Tooltip>
            </div>
          </>
        ),
      };
    });
    return transformed_fees;
  }

  const classes = useStyles();

  const columns = [
    {
      label: "#",
      name: "id",
    },
    {
      label: "Amount($)",
      name: "amount",
    },
    {
      label: "Duration",
      name: "duration",
    },
    {
      label: "Actions",
      name: "actions",
    },
  ];
  const options = {
    filter: false, // Hide filters
    print: false, // Hide print
    download: false, // Hide download
    selectableRowsHeader: false, // Hide checkbox in header
    selectableRowsHideCheckboxes: true, // Hide checkbox for every row
    customToolbar: () => {
      return (
        <Tooltip title="Add FeeStructure" aria-label="">
          <IconButton
            onClick={() => {
              history.push("/fee-structure/add");
            }}
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      );
    },
  };
  return (
    <GridContainer>
      <div className={classes.cardContentLeft}></div>
      <GridItem xs={12}>
        <Card>
          {data && (
            <MUIDataTable
              title={
                <div>
                  <CardIcon color="rose">
                    <MonetizationOnIcon style={{ color: "white" }} />{" "}
                  </CardIcon>
                  <Typography variant="h6">Fee Structure</Typography>
                </div>
              }
              data={data}
              columns={columns}
              options={options}
            />
          )}
        </Card>
      </GridItem>
    </GridContainer>
  );
}
