import Axios from "axios";

const requests = [];
const { CancelToken } = Axios;
let api = Axios;

const getUsers = async (query) => {
  try {
    let cancel;
    const response = await api.post("/admin/users", query, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const deleteUser = async (id) => {
  try {
    let cancel;
    const response = await api.delete("/admin/user/" + id, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getUser = async (id) => {
  try {
    let cancel;
    const response = await api.get("/admin/user/" + id, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getUserSubscribedPlans = async (id) => {
  try {
    console.log("here");
    let cancel;
    const response = await api.get("/admin/user-plans/" + id, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const updateUser = async (data) => {
  try {
    let cancel;
    const response = await api.put("/admin/user", data, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const init = async (axiosApi) => {
  api = axiosApi;
};

const UserService = {
  init,
  getUsers,
  updateUser,
  deleteUser,
  getUser,
  getUserSubscribedPlans,
};

export default UserService;
